import React, { useState } from 'react';
import { Paper, Typography, Box, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import { keyframes } from '@emotion/react';

// Keyframes for animations
const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const Task: React.FC = () => {
  const [hintsVisible, setHintsVisible] = useState<boolean[]>([true, false, false]); // Control visibility of hints

  // Fake a timed reveal of hints
  const unlockHint = (index: number) => {
    let newHintsVisible = [...hintsVisible];
    newHintsVisible[index] = true;
    setHintsVisible(newHintsVisible);
  };

  return (
    <Paper elevation={2} sx={{ padding: '20px', margin: '10px', backgroundColor: '#f0f0f0', animation: `${fadeIn} 1s` }}>
      <Typography variant="h6" sx={{ marginBottom: '12px' }}>Úloha na programovanie v Scratchi</Typography>
      <Typography variant="body1">
        Úlohou tohto týždňa je vytvoriť jednoduchú hru v Scratchi. Vaším cieľom je programovať postavičku, aby sa pohybovala po obrazovke na základe vstupu z klávesnice.
      </Typography>
      <Box mt={2}>
        <Typography variant="body2">Ciele:</Typography>
        <List>
          <ListItem>
            <ListItemIcon><PlayCircleOutlineIcon /></ListItemIcon>
            <ListItemText>Vytvorte projekt v Scratchi a pridajte postavičku.</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon><PlayCircleOutlineIcon /></ListItemIcon>
            <ListItemText>Programujte postavičku, aby sa pohybovala doľava a doprava keď sú stlačené šípkové klávesy.</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon><PlayCircleOutlineIcon /></ListItemIcon>
            <ListItemText>Pridajte pozadie a uistite sa, že postavička nevyjde mimo hranice obrazovky.</ListItemText>
          </ListItem>
        </List>
        <Typography variant="body2">Tipy:</Typography>
        <List>
          <ListItem>
            <ListItemIcon><LightbulbOutlinedIcon /></ListItemIcon>
            <ListItemText>Experimentujte s rôznymi rýchlosťami a pohybmi.</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon><LightbulbOutlinedIcon /></ListItemIcon>
            <ListItemText>Použite bloky 'Events' a 'Control' na interakciu s postavičkou.</ListItemText>
          </ListItem>
        </List>
        <Typography variant="body2" sx={{ mt: 2 }}>Bonusové tipy (odomykateľné):</Typography>
        <List>
          {hintsVisible.map((visible, index) => (
            <ListItem key={index}>
              <ListItemIcon>
                {visible ? <LightbulbOutlinedIcon /> : <LockIcon onClick={() => unlockHint(index)} />}
              </ListItemIcon>
              <ListItemText primary={visible ? `Bonusový tip ${index + 1}: Použite zložitejšie logické bloky na vylepšenie hry.` : "Tip sa odomyká zajtra."} />
            </ListItem>
          ))}
        </List>
      </Box>
    </Paper>
  );
};

export default Task;
