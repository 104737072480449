import { Button, Container } from "@mui/material";
import { useState } from "react";
import CourseCard, { DifficultyLevel, Language } from "../CourseCard/CourseCard";

interface CoursesProps {
  isDarkTheme: boolean;
  userId: string;
}

enum gradeLevel {
  first = "first",
  second = "second",
}

const courseCardData = [
  {
    language: Language.SCRATCH,
    title: "Pokročilé programovanie v jazyku Scratch",
    description:
      "Pokročilé programovanie v jazyku Scratch je určené pre deti, ktoré už majú s programovaním skúsenosti a chcú sa naučiť niečo nové. V tomto kurze sa naučíte, ako vytvoriť vlastný program, ktorý bude obsahovať všetky základné prvky programovania.",
    difficultyLevel: DifficultyLevel.MEDIUM || DifficultyLevel.EASY,
    id: 6,
    price: "7-10€ / hod",
    courseDuration: "1 hodina (1x týždenne, celý polrok)",
    capacity: 10,
    suitableFor: "Deti od 5. - 9. ročník",
    gradeLevel: "first",
    conditions: "Scratch I - základy programovania absolvované",
  },
  {
    language: Language.ROBLOX,
    title: "Úvod do programovania v jazyku Roblox",
    description:
      "Priblížte sa k svetu digitálnej tvorby a herného dizajnu prostredníctvom platformy Roblox. Roblox nie je len obľúbenou hernou platformou, ale aj nástrojom, ktorý umožňuje užívateľom vytvárať vlastné hry a virtuálne prostredia. Vďaka svojej integrovanosti s jazykom Lua, Roblox ponúka začiatočníkom priateľský úvod do programovania, zatiaľ čo skúsenejším vývojárom poskytuje nástroje potrebné na vytváranie zložitejších projektov.",
    difficultyLevel: DifficultyLevel.MEDIUM,
    id: 5,
    price: "7-10€ / hod",
    courseDuration: "1 hodina (1x týždenne, celý polrok)",
    capacity: 10,
    suitableFor: "Deti od 5. - 9. ročník",
    gradeLevel: "second",
    conditions: "Scratch I - základy programovania absolvované alebo Python I - základy programovania absolvované",
  },
  {
    language: Language.SCRATCH,
    title: "Úvod do programovania v jazyku Scratch",
    description:
      "Programovanie v jazyku Scratch je vhodné už aj pre tých najmenších. Spolu sa hravo zoznámime s tým, čo vlastne programovanie je a ukážeme, že deti môžu skutočne začať vytvárať jednoduché hry už v druhom ročníku. A pri tom budú skutočne programovať!",
    difficultyLevel: DifficultyLevel.EASY,
    id: 1,
    price: "7-10€ / hod",
    courseDuration: "1 hodina (1x týždenne, celý polrok)",
    capacity: 10,
    suitableFor: "Deti od 2. - 4. ročník",
    gradeLevel: "first",
  },
  {
    language: Language.PYTHON,
    title: "Úvod do programovania v jazyku Python",
    description:
      "Naučte sa základy programovania vytváraním zábavných interaktívnych príbehov, hier a animácií pomocou Pythonu. Python je výborným výberom pre začiatočníkov vďaka jeho čitateľnosti a jednoduchosti, ale aj pre skúsenejších programátorov, ktorí chcú rozšíriť svoje znalosti.",
    difficultyLevel: DifficultyLevel.EASY,
    id: 2,
    price: "7-10€ / hod",
    courseDuration: "1 hodina (1x týždenne, celý polrok)",
    capacity: 10,
    suitableFor: "Deti od 5. - 9. ročník",
    gradeLevel: "second",
  },
  {
    language: Language.JAVASCRIPT,
    title: "Úvod do programovania v jazyku JavaScript",
    description:
      "Naučte sa základy programovania prostredníctvom vytvárania dynamických webových stránok a interaktívnych aplikácií pomocou JavaScriptu. JavaScript je ideálny pre tých, ktorí chcú vstúpiť do sveta webového vývoja s jeho všestrannosťou a flexibilitou, a je skvelým jazykom pre nováčikov aj pre odborníkov, ktorí sa snažia prehlbovať svoje dovednosti v oblasti webovej technológie.",
    difficultyLevel: DifficultyLevel.HARD,
    id: 3,
    price: "7-10€ / hod",
    courseDuration: "1 hodina (1x týždenne, celý polrok)",
    capacity: 10,
    suitableFor: "Deti od 5. - 9. ročník",
    gradeLevel: "second",
  },
  // same with HTML
  {
    language: Language.HTML,
    title: "Úvod do programovania v jazyku HTML",
    description:
      "Naučte sa základy programovania prostredníctvom vytvárania dynamických webových stránok a interaktívnych aplikácií pomocou HTML. HTML je ideálny pre tých, ktorí chcú vstúpiť do sveta webového vývoja s jeho všestrannosťou a flexibilitou, a je skvelým jazykom pre nováčikov aj pre odborníkov, ktorí sa snažia prehlbovať svoje dovednosti v oblasti webovej technológie.",
    difficultyLevel: DifficultyLevel.HARD,
    id: 7,
    price: "7-10€ / hod",
    courseDuration: "1 hodina (1x týždenne, celý polrok)",
    capacity: 10,
    suitableFor: "Deti od 5. - 9. ročník",
    gradeLevel: "second",
  },
  {
    language: Language.TYPESCRIPT,
    title: "Pokročilé programovania v jazyku TypeScript",
    description:
      "Vstúpte hlbšie do sveta moderného webového a aplikačného vývoja s TypeScriptom. TypeScript, rozšírenie jazyka JavaScript, pridáva statické typy a mnoho ďalších vlastností, čím umožňuje vytvárať robustné, efektívne a bezpečné aplikácie pre rôzne platformy. Tento kurz je určený pre tých, ktorí sú už zoznámení so základmi TypeScriptu a chcú rozšíriť svoje znalosti a dovednosti na vyššiu úroveň.",
    difficultyLevel: DifficultyLevel.HARD,
    id: 4,
    price: "7-10€ / hod",
    courseDuration: "1 hodina (1x týždenne, celý polrok)",
    capacity: 10,
    suitableFor: "Deti od 5. - 9. ročník",
    gradeLevel: "second",
    conditions: "JavaScript I - základy programovania absolvované",
  },
];

function Courses({ isDarkTheme, userId }: CoursesProps) {
  //Newly added state for selected difficulty
  const [selectedDifficulty, setSelectedDifficulty] = useState<DifficultyLevel | "">("");
  const [selectedGradeLevel, setSelectedGradeLevel] = useState<gradeLevel | "">("");

  return (
    <Container maxWidth="md" sx={{ py: 5, px: 2 }}>
      <div>
        <label>ZŠ stupeň:   </label>
        <Button
          variant="outlined"
          color={selectedGradeLevel === "first" ? "success" : "inherit"}
          style={{ marginRight: "5px" }}
          onClick={() => setSelectedGradeLevel(selectedGradeLevel === "first" ? "" : gradeLevel.first)}
        >
          1.Stupeň
        </Button>
        <Button
          variant="outlined"
          color={selectedGradeLevel === "second" ? "success" : "inherit"}
          onClick={() => setSelectedGradeLevel(selectedGradeLevel === "second" ? "" : gradeLevel.second)}
        >
          2.Stupeň
        </Button>
      </div>
      <br />
      <div>
        <label>Náročnosť: </label>
        <Button
          variant="outlined"
          color={selectedDifficulty === DifficultyLevel.EASY ? "success" : "inherit"}
          style={{ marginRight: "2.5px" }}
          onClick={() => setSelectedDifficulty(selectedDifficulty === DifficultyLevel.EASY ? "" : DifficultyLevel.EASY)}
        >
          {DifficultyLevel.EASY}
        </Button>
        <Button
          variant="outlined"
          color={selectedDifficulty === DifficultyLevel.MEDIUM ? "success" : "inherit"}
          style={{ marginRight: "2.5px", marginLeft: "2.5px" }}
          onClick={() =>
            setSelectedDifficulty(selectedDifficulty === DifficultyLevel.MEDIUM ? "" : DifficultyLevel.MEDIUM)
          }
        >
          {DifficultyLevel.MEDIUM}
        </Button>
        <Button
          variant="outlined"
          color={selectedDifficulty === DifficultyLevel.HARD ? "success" : "inherit"}
          style={{ marginRight: "2.5px", marginLeft: "2.5px" }}
          onClick={() => setSelectedDifficulty(selectedDifficulty === DifficultyLevel.HARD ? "" : DifficultyLevel.HARD)}
        >
          {DifficultyLevel.HARD}
        </Button>
      </div>
      <br />
      {courseCardData
        .filter(
          (course) =>
            (selectedDifficulty ? course.difficultyLevel === selectedDifficulty : true) &&
            (selectedGradeLevel ? course.gradeLevel === selectedGradeLevel : true)
        )
        .map((courseCard, index) => (
          <div key={index}>
            <CourseCard
              price={courseCard.price}
              courseDuration={courseCard.courseDuration}
              capacity={courseCard.capacity}
              suitableFor={courseCard.suitableFor}
              language={courseCard.language}
              title={courseCard.title}
              description={courseCard.description}
              difficultyLevel={courseCard.difficultyLevel}
              conditions={courseCard.conditions ? courseCard.conditions : ""}
              gradeLevel={courseCard.gradeLevel}
              isDarkTheme={false} // isDarkTheme={isDarkTheme}
            />
            <br />
          </div>
        ))}
    </Container>
  );
}

export default Courses;
