import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../Auth/AuthContext";
import NotLoggedIn from "../Login/NotLoggedIn";
import Cookie from "js-cookie";
import "./style/adminChildren.css";

interface Child {
  _id: string;
  name: string;
  schoolId: string;
  courseId: string;
  userId: string;
  createdAt: Date;
  className: string;
  isAttending: boolean;
  firstPayment: boolean;
  nextPayment: number;
  weeklyChallenges: boolean;
}

interface AdminChildrenProps {
  userId: string;
}

const AdminChildren = ({ userId }: AdminChildrenProps) => {
  const [children, setChildren] = useState<Child[]>([]);
  const { token } = useAuth();

  const url =
    process.env.REACT_APP_ENVIRONMENT === "prod" ? process.env.REACT_APP_PROD_URL : process.env.REACT_APP_DEV_URL;

  useEffect(() => {
    const fetchChildren = async () => {
      if (!token || !userId) return;
      try {
        const response = await fetch(url + "children/allChildrens", {
          headers: {
            Authorization: `Bearer ${token}`,
            "X-User-Id": userId, // custom header for user ID
          },
        });
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        const data = await response.json();
        setChildren(data.children);
      } catch (error) {
        console.error("Error fetching children:", error);
      }
    };

    fetchChildren();
  }, [token, url, userId]);

  const removeFromCourse = async (childId: string) => {
    try {
      const response = await fetch(`${url}children/removeFromCourse/${childId}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "X-User-Id": userId,
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }

      await response.json();

      const newChildrenList = children.filter((child) => child._id !== childId);
      setChildren(newChildrenList);
    } catch (error) {
      console.error("Error removing child from course:", error);
    }
  };

  if (!token) {
    const tokenFromCookie = Cookie.get("token");
    if (!tokenFromCookie) {
      return <NotLoggedIn />;
    }
  }

  return (
    <div className="admin-children-container">
      <h3>List of Children</h3>
      <table className="children-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>UserId</th>
            <th>Class Name</th>
            <th>First Payment</th>
            <th>Next Payment</th>
            <th>Weekly Challenges</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {children.map((child) => (
            <tr key={child._id}>
              <td>{child.name}</td>
              <td>{child.userId}</td>
              <td>{child.className}</td>
              <td>{child.firstPayment ? "Yes" : "No"}</td>
              <td>{child.nextPayment}</td>
              <td>{child.weeklyChallenges ? "Yes" : "No"}</td>
              <td>
                <Link to={`/user/${child.userId}/details`}>
                  <button className="details-button">Parent Details</button>
                </Link>
                <Link to={`/children/${child._id}/details`}>
                  <button className="details-button">Child Details</button>
                </Link>
                <button className="remove-button" onClick={() => removeFromCourse(child._id)}>
                  Remove from Course
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AdminChildren;
