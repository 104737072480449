import React, { useState } from "react";
import ProgrammingCircleInfo from "../School/ProgrammingCircleInfo";
import "./CoursesWork.css";

interface FAQ {
  question: string;
  answer: string;
}

const FAQS: FAQ[] = [
  {
    question: "Pre koho sú kurzy vhodné?",
    answer:
      "Kurz je určený predovšetkým žiakov, ktorých zaujímajú moderné technológie, IT výdobytky alebo počítačové hry a snívajú o tom raz sa naučiť programovať. Tiež pre tých, ktorí majú v sebe skrytých umelcov. Žiadne špeciálne znalosti, skúsenosti s programovaním alebo tzv. technické myslenie nie sú požadované - a ani najlepšie možné známky z matematiky. Na všetko sa spolu pozrieme a naučíme. Vekové obmedzenie je stanovené na žiakov od 2. do 9. ročníka základnej školy."
  },
  {
    question: "Kým boli vytvorené programovacie kurzy?",
    answer:
    "Vyučovacie plány a obsah všetkých kurzov bol navrhnutý a vytvorený tímom skúsených učiteľov a lektorov, ktorí majú skúsenosti v informatike a pedagogike. Naši lektori učitelia majú skúsenosti s prácou na technických projektoch a samozrejme v školách. Priebežne spolu aktualizujeme obsah už existujúcich kurzov a nezabúdame ani na najnovšie trendy v IT, vzdelanie a životy detí."
  },
  {
    question: "Čo bude moje dieťa potrebovať aby sa mohlo zúčastňovať lekcií?",
    answer:
    "Nakoľko sa všetky naše kury prebiehajú v priestoroch informatickej miestnosti školy, nie je potrebné žiadne špeciálne vybavenie. Stačí, ak Vaše dieťa príde na lekciu s dobrou náladou a chuťou učiť sa nové veci. Všetky potrebné pomôcky a techniku zabezpečíme my."
  },
  {
    question: "Kde by som mohol nazrieť do plánovaných rozvrhov jednotlivých skupín?",
    answer:
      "Po zaslaní žiadosti Vás bude kontaktovať náš konzultant. Spoločne vyberiete skupinu a vymyslí pre Vás rozvrh, ktorý Vám bude čo najviac vyhovovať. Samozrejme Vám vo vybranej výukovej skupine zarezervuje miesto - je našou úlohou sa prispôsobiť potrebám študentov a ich rodičov.",
  },
  {
    question: "Čo v prípade, že moje dieťa vymešká jednu z hodín?",
    answer:
      "Nemusíte sa ničoho báť. V prípade, že Vaše dieťa zamešká ktorúkoľvek z našich lekcii, je možnosť náhrady v danom týždni na inom cvičení. Ak má akékoľvek otázky, môže sa obrátiť na lektora, ktorý mu rád pomôže. V prípade, že by učivo vzbudzovalo priveľa otázok, môžete sa dohodnúť na súkromnej hodine mimo riadneho vyučovania, aby nemalo pocit, že niečo nestíha a zaostáva za zvyškom triedy."
  },
  {
    question: "Ako dlho trvá jeden kurz?",
    answer:
    "Kurz trvá zvyčajne celý školôský polrok, pokial nie je dohoda s rodičmi a riaditeľom skoly iná."
  },
  {
    question: "Obdrží moje dieťa certifikát po absolvovaní a úspešnom zvládnutí kurzu?",
    answer:
    "Samozrejme! Každý z našich absolventov obdrží certifikát potvrdzujúci nadobudnuté vzdelanie z projektu Mladí programátori."
  },
  {
    question: "Koľko stojí kurz na mojej ZŠ?",
    answer:
    "Cena sa odvíja od mesta, v ktorom sa nachádza ZŠ žiaka, od poplatku za prenájom miestnosti informatiky a od obsadenosti žiakmi."
  }

];

const CoursesWork: React.FC = () => {
  const [selectedFAQ, setSelectedFAQ] = useState<null | number>(null);

  return (
    <>
      <ProgrammingCircleInfo infoEmail="mladiprogramatori.info@gmail.sk" />
      <div className="courses-work-container">
        <h1>Ako fungujú naše kurzy</h1>
        <div className="faq-container">
          {FAQS.map((faq, index) => (
            <div key={index} className="faq-item">
              <h2 onClick={() => setSelectedFAQ(selectedFAQ === index ? null : index)}>{faq.question}</h2>
              {selectedFAQ === index && <p>{faq.answer}</p>}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default CoursesWork;
