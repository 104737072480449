import { useEffect, useState } from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import Cookies from "js-cookie";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Courses from "./components/Courses/Courses";
import Login from "./components/Login/Login";
import Register from "./components/Login/Register";
import ForgotPassword from "./components/Login/ForgotPassword";
import LoggedInHeader from "./components/Header/UserHeader";
import AdminHeader from "./components/Header/AdminHeader";
import Payments from "./components/Payments/Payments";
import UserBoard from "./components/User/UserBoard";
import AttendCourse from "./components/Attendance/AttendCourse";
import SubHeader from "./components/Header/SubHeader";
import Main from "./components/Main/Main";
import School from "./components/School/School";
import AboutUs from "./components/AboutUs/AboutUs";
import Contact from "./components/Contact/Contact";
import Profile from "./components/Profil/Profil";
import SetNewPassword from "./components/Login/SetNewPassword";
import SuccessPage from "./components/Pages/SuccessPage";
import CancelPage from "./components/Pages/CancelPage";
import AdminSchool from "./components/Admin/AdminSchool";
import AdminUser from "./components/Admin/AdminUser";
import AdminCourse from "./components/Admin/AdminCourse";
import AdminLector from "./components/Admin/AdminLector";
import AdminPayment from "./components/Admin/AdminPayment";
import AdminChildren from "./components/Admin/AdminChildren";
import AdminDashboard from "./components/Admin/AdminDashboard";
import AdminAttendance from "./components/Admin/AdminAttendance";
import CourseDetails from "./components/Courses/CourseDetails";
import LectorDetails from "./components/Lector/LectorDetails";
import AssignLectorToCourse from "./components/Admin/AssignLectorToCourse";
import AssignCourseToLector from "./components/Admin/AssignCourseToLector";
import UserDetails from "./components/User/UserDetails";
import ChildrenDetails from "./components/Children/ChildrenDetails";
import LectorHeader from "./components/Header/LectorHeader";
import LectorDashboard from "./components/Lector/LectorDashboard";
import LectorCourses from "./components/Lector/LectorCourses";
import LectorAttendance from "./components/Lector/LectorAttendence";
import LectorAttendanceHistory from "./components/Lector/LectorAttendenceHistory";
import LectorAttendanceHistoryDetailsProps from "./components/Lector/LectorAttendenceHistoryDetails";
import UserChildrens from "./components/Children/UserChildrens";
import CookieConsent from "./components/Cookie/CookieConsent";
import CoursesWork from "./components/Courses/CoursesWork";
import CookiePolicy from "./components/Cookie/CookiePolicy";
import TermsAndConditions from "./components/TermsAndConditions/TermsAndConditions";
import CourseDetailsUser from "./components/Courses/CourseDetailsUser";
import StopAttending from "./components/Children/StopAttanding";
import UserCourses from "./components/Courses/UserCourses";
import ChildUserDetails from "./components/Children/ChildUserDetails";
import CoursesOfSchool from "./components/Courses/CoursesOfSchool";
import PrivacyPolicy from "./components/PersonalTerms/PrivacyPolicy";
import CourseInterest from "./components/Courses/CourseInterest";
import RegistrationCircle from "./components/RegistrationCircle/RegistrationCircle";
import ComingSoon from "./components/ComingSoon/ComingSoon";
import FileDownload from "./components/FileDownload/FileDownload";
import LectorProfile from "./components/Profil/LectorProfile";
import Request from "./components/Request/Request";
import AdminRequest from "./components/Admin/AdminRequest";
import AdminInterest from "./components/Admin/AdminInterest";
import Cookie from "js-cookie";
import { AuthProvider } from "./components/Auth/AuthContext";
import { fetchUserData } from "./utils/api";
import { useAuth } from "./components/Auth/AuthContext";
import "./Styles/global.css";
import ChildrenRegistration from "./components/Children/ChildrenRegistration";
import MonthlyChallenges from "./components/MonthlyChallenges/MonthlyChallenges";
import TasksDisplay from "./components/TasksDisplay/TasksDisplay";
import WeeklyChallengeOverview from "./components/WeeklyChallengeOverview/WeeklyChallengeOverview";
import Online from "./components/Online/Online";

function InnerApp(): JSX.Element {
  const [isDarkTheme, setIsDarkTheme] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userId, setUserId] = useState("");
  const [userData, setUserData] = useState("");
  const { setToken, token } = useAuth();

  const toggleTheme = () => {
    setIsDarkTheme(!isDarkTheme);
  };

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setUserId("");
    setUserData("");
    Cookies.remove("userId");
    Cookies.remove("token");
    setToken(null);
  };

  useEffect(() => {
    const userIdFromCookie = Cookies.get("userId");
    const tokenFromCookie = Cookies.get("token");

    if (userIdFromCookie) {
      setIsLoggedIn(true);
      setUserId(userIdFromCookie);
    }

    if (userIdFromCookie && tokenFromCookie && userData === "") {
      fetchUserData(userIdFromCookie, tokenFromCookie)
        .then((user) => {
          setUserData(user);
        })
        .catch((error) => console.log(error));
    }
  }, [userData]);

  // if userId and token are in cookie and user is not logged in, log in user
  useEffect(() => {
    const userId = Cookies.get("userId");
    const token = Cookies.get("token");

    if (userId && token && userData === "") {
      // if userData are empty, fetch them
      fetchUserData(userId, token)
        .then((user) => setUserData(user))
        .catch((error) => console.log(error));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const userId = Cookies.get("userId");

    const removeCookiesAfter15Minutes = () => {
      const inactivityTimeout = setTimeout(() => {
        Cookies.remove("userId");
        Cookies.remove("token");
        setUserId("");
        setToken(null);
        setIsLoggedIn(false);
        setUserId("");
        setUserData("");
      }, 15 * 60 * 1000); // 15 minutes in milliseconds

      return () => clearTimeout(inactivityTimeout);
    };

    const getUserData = async () => {
      if (userId) {
        setIsLoggedIn(true);
        setUserId(userId);
        removeCookiesAfter15Minutes(); // Start the timeout when userId is present
        if (!token) {
          const tokenFromCookie = Cookie.get("token");

          fetchUserData(userId, tokenFromCookie ? tokenFromCookie : "")
            .then((user) => setUserData(user))
            .catch((error) => console.log(error));
        } else {
          fetchUserData(userId, token ? token : "")
            .then((user) => setUserData(user))
            .catch((error) => console.log(error));
        }
      }
    };
    getUserData();
  }, [setToken, token, userId]);

  const getUserData2 = async () => {
    if (userId) {
      setIsLoggedIn(true);
      setUserId(userId);
      if (!token) {
        const tokenFromCookie = Cookie.get("token");
        fetchUserData(userId, tokenFromCookie ? tokenFromCookie : "")
          .then((user) => setUserData(user))
          .catch((error) => console.log(error));
      } else {
        fetchUserData(userId, token ? token : "")
          .then((user) => setUserData(user))
          .catch((error) => console.log(error));
      }
    }
  };

  return (
    <>
      <div className="app-container">
        <Router>
          <div id="header-container">
            <div id="main-header">
              <Header title="ExampleCo Code Kids" toggleThemas={toggleTheme} userId={userId} userData={userData} />
            </div>
            <SubHeader title="" />
          </div>
          {isLoggedIn ? <LoggedInHeader handleLogout={handleLogout} userId={userId} userData={userData} /> : null}
          {isLoggedIn ? <AdminHeader handleLogout={handleLogout} userId={userId} userData={userData} /> : null}
          {isLoggedIn ? <LectorHeader handleLogout={handleLogout} userId={userId} userData={userData} /> : null}
          <Routes>
            <Route path="/" element={<Main userId={userId} />} />
            <Route path="/register" element={<Register />} />
            <Route path="/login/private" element={<ComingSoon text={"Prihlasovanie"} />} />
            <Route path="/register/private" element={<ComingSoon text={"Registrácia"} />} />
            <Route path="/login" element={<Login onLogin={handleLogin} setUserId={setUserId} />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/payments" element={<Payments userId={userId} />} />
            <Route path="/userboard" element={<UserBoard />} />
            <Route path="/courses" element={<Courses isDarkTheme={isDarkTheme} userId={userId} />} />
            <Route path="/attend" element={<AttendCourse userId={userId} userData={userData} />} />
            <Route path="/schools" element={<School />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/online-courses" element={<Online />} />
            <Route
              path="/profile"
              element={<Profile userId={userId} userData={userData} updateUserData={getUserData2} />}
            />
            <Route
              path="/lector-profile"
              element={<LectorProfile userId={userId} userData={userData} updateUserData={getUserData2} />}
            />
            <Route path="/set-new-password/:token" element={<SetNewPassword />} />
            <Route path="/success" element={<SuccessPage userId={userId} />} />
            <Route path="/cancel" element={<CancelPage userId={userId} />} />
            <Route path="/admin/school" element={<AdminSchool userId={userId} />} />
            <Route path="/admin/user" element={<AdminUser userId={userId} />} />
            <Route path="/admin/course" element={<AdminCourse userId={userId} />} />
            <Route path="/admin/lector" element={<AdminLector userId={userId} />} />
            <Route path="/admin/requests" element={<AdminRequest userId={userId} />} />
            <Route path="/admin/payment" element={<AdminPayment userId={userId} />} />
            <Route path="/admin/interest" element={<AdminInterest userId={userId} />} />
            <Route path="/admin/children" element={<AdminChildren userId={userId} />} />
            <Route path="/admin/dashboard" element={<AdminDashboard />} />
            <Route path="/admin/attendance" element={<AdminAttendance userId={userId} />} />
            <Route path="/course/details/:id" element={<CourseDetails />} />
            <Route path="/course/details-user/:id" element={<CourseDetailsUser />} />
            <Route path="/lector/:id/details" element={<LectorDetails />} />
            <Route path="/assign-lector-to-course/:id" element={<AssignLectorToCourse userId={userId} />} />
            <Route path="/assign-course-to-lector/:id" element={<AssignCourseToLector userId={userId} />} />
            <Route path="/user/:id/details" element={<UserDetails />} />
            <Route path="/children/:id/details" element={<ChildrenDetails userId={userId} />} />
            <Route path="/lector/dashboard" element={<LectorDashboard />} />
            <Route path="/lector/course" element={<LectorCourses lectorId={userId} />} />
            <Route path="/lector/attendance" element={<LectorAttendance lectorId={userId} />} />
            <Route path="/lector/attendance-history" element={<LectorAttendanceHistory lectorId={userId} />} />
            <Route
              path="/lector/attendance-history/:id"
              element={<LectorAttendanceHistoryDetailsProps userId={userId} />}
            />
            <Route
              path="/user/children"
              element={<UserChildrens userId={userId} userData={userData} updateUserData={getUserData2} />}
            />
            <Route
              path="/user/children/add"
              element={<ChildrenRegistration userId={userId} userData={userData} updateUserData={getUserData2} />}
            />
            <Route path="/coursesWork" element={<CoursesWork />} />
            <Route path="/cookiePolicy" element={<CookiePolicy />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
            <Route path="/children/:childrenId/stop-attending" element={<StopAttending userId={userId} />} />
            <Route path="/user/courses/:userId" element={<UserCourses />} />
            <Route path="/children/user/:childrenId/details" element={<ChildUserDetails userId={userId} />} />
            <Route path="/courses-of-school/:schoolId" element={<CoursesOfSchool />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/course-interest" element={<CourseInterest />} />
            <Route path="/registration-circle" element={<RegistrationCircle />} />
            <Route path="/for-download" element={<FileDownload />} />
            <Route path="/requestttt" element={<Request userId={userId} />} />
            <Route path="/monthly-challenges" element={<MonthlyChallenges />} />
            <Route path="/tasks-display" element={<TasksDisplay />} />
            <Route path="/weekly-challenge-overview" element={<WeeklyChallengeOverview />} />
          </Routes>
          <div style={{ paddingBottom: "64px" }}>
            {" "}
            <CookieConsent
              cookieName="MladiProgramatoriCookie"
              cookieDuration={30}
              message={`Na zabezpečenie správneho fungovania našej stránky "Mladí programátori" sme do vášho zariadenia uložili malé súbory, tzv. cookies. Okrem základných súborov by sme chceli využívať aj analytické a marketingové cookies, ktoré nám poskytujú informácie o vašom zariadení a správaní na našej stránke. Tieto údaje nám pomáhajú vylepšovať vaše používateľské skúsenosti, prispôsobovať obsah a reklamu a monitorovať návštevnosť našej stránky. Informácie zdieľame aj s našimi reklamnými partnermi. Chcete vedieť viac? Pozrite si naše zásady používania cookies. Súhlasíte s použitím analytických a marketingových cookies na zlepšenie našich služieb?`}
              buttonTextAllow="Prijať Cookies"
              buttonTextReject="Odmietnuť Cookies"
            />
          </div>
          <Footer copyrightText={"2023 | mladiprogramatori.sk"} isDarkTheme={isDarkTheme} />
        </Router>
      </div>
    </>
  );
}

function App(): JSX.Element {
  return (
    <AuthProvider>
      <InnerApp />
    </AuthProvider>
  );
}

export default App;
