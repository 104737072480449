import React, { useState } from "react";
import { Box, Paper, Typography, Stepper, Step, StepLabel, StepContent, Button, Collapse } from "@mui/material";
import { keyframes } from "@emotion/react";

// Keyframe animations
const slideInLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-30px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const WeeklyChallengeOverview: React.FC = () => {
  const [showMore, setShowMore] = useState(false);
  const steps = [
    {
      label: "Ako to prebieha",
      description: `Každý týždeň dostanú účastníci nové výzvy. Tieto úlohy sú navrhnuté tak, aby postupne budovali a rozširovali znalosti v oblasti programovania.`,
    },
    {
      label: "Počet úloh a časový rámec",
      description:
        "Účastníci majú k dispozícii desať interaktívnych úloh každý mesiac, s týždennými cieľmi pre každú úlohu.",
    },
    {
      label: "Vzdelávacie ciele",
      description:
        "Kurz pokrýva rozmanité témy, od základov programovania, algoritmov až po pokročilé koncepty v informatike.",
    },
    {
      label: "Cena",
      description: "Program je cenovo dostupný a poskytuje vysokú hodnotu za investíciu do vzdelávania vášho dieťaťa.",
    },
  ];

  const moreInfoContent = `Náš program je určený pre deti, ktoré majú záujem o programovanie a technológie. Naším cieľom je urobiť ich samostatnými tvorcami, nie len spotrebiteľmi digitálneho obsahu. Poskytujeme podporu a usmerňujeme ich prostredníctvom celého procesu učenia, od začiatočníckych krokov až po pokročilé projekty.`;

  const weeklySteps = [
    "Prihlásenie na výzvu",
    "Dostanete úlohu",
    "Riešenie úlohy",
    "Odoslanie úlohy",
    "Získanie riešenia",
    "Feedback a nová úloha",
  ];

  return (
    <Box sx={{ maxWidth: 700, margin: "auto", mt: -2, p: 2, animation: `${slideInLeft} 1s ease-out` }}>
      <Paper sx={{ my: 4, p: 2 }}>
        <Typography variant="h5" align="center" sx={{ my: 2 }}>
          Ako prebieha týždenná výzva
        </Typography>
        <Stepper activeStep={-1} alternativeLabel>
          {weeklySteps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Paper>
      <Paper elevation={4} sx={{ p: 3 }}>
        <Typography variant="h5" sx={{ mb: 3, textAlign: "center" }}>
          Týždenný prehľad výziev
        </Typography>
        <Stepper orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.label} active={true}>
              <StepLabel>{step.label}</StepLabel>
              <StepContent>
                <Typography>{step.description}</Typography>
                <Collapse in={showMore || index < steps.length - 1}>
                  <Typography sx={{ mt: 2 }}>{moreInfoContent}</Typography>
                </Collapse>
                {index === steps.length - 1 && (
                  <Button sx={{ mt: 2 }} variant="outlined" onClick={() => setShowMore(!showMore)}>
                    {showMore ? "Zobraziť menej" : "Zobraziť viac"}
                  </Button>
                )}
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Paper>
    </Box>
  );
};

export default WeeklyChallengeOverview;
