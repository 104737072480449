import React, { useEffect, useState } from "react";
import { AppBar, Toolbar, Typography } from "@mui/material";
import { FiMail, FiFacebook, FiInstagram, FiLinkedin, FiUser } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

import "../../Styles/darkMode.css";
import "./headerStyles.css"; // Import the new CSS file

interface HeaderProps {
  title: string;
  toggleThemas: (theme: string) => void;
  userId: string;
  userData: any;
}

const Header = ({ title, toggleThemas, userId, userData }: HeaderProps) => {
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const navigate = useNavigate();
  const [message, setMessage] = useState<string>("Môj účet(prihlasit sa)");

  useEffect(() => {
    if (userId && userData) {
      if (userData.name === undefined || userData.surname === undefined) {
        setMessage(userData.email);
      } else {
        setMessage(userData.name + " " + userData.surname);
      }
    } else {
      setMessage("Môj účet");
    }
  }, [userData, userId]);

  const handleClick = () => {
    navigate("/#");
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <AppBar position="static" sx={{ backgroundColor: "#A53F29" }}>
      <Toolbar sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* {isMobile ? null : (
          <a
            href="tel:0940 014 553"
            style={{ display: "flex", alignItems: "center", textDecoration: "none", color: "inherit" }}
          >
            <FiPhone size={20} />
            <Typography variant="body2" color="inherit" sx={{ mx: 2 }}>
              0940 014 553
            </Typography>
          </a>
        )} */}
          {isMobile ? null : (
            <a
              href="mailto:info@mladiprogramatori.sk"
              style={{
                display: "flex",
                alignItems: "center",
                textDecoration: "none",
                color: "inherit",
                marginLeft: "10px",
              }}
            >
              <FiMail size={20} />
              <Typography variant="body2" color="inherit" sx={{ ml: 1 }}>
                info@mladiprogramatori.sk
              </Typography>
            </a>
          )}
        </div>
        {isMobile ? (
          <a href="/#" onClick={handleClick}>
            <img
              src="https://mladi-programatori-aws-s3.s3.eu-north-1.amazonaws.com/no_background_img.png"
              alt="logo"
              style={{
                maxWidth: 124,
                maxHeight: 74,
                height: "auto",
                marginLeft: -5,
                marginRight: 16,
                flex: 1,
              }}
            />
          </a>
        ) : (
          <a href="/#" onClick={handleClick}>
            <img
              src="https://mladi-programatori-aws-s3.s3.eu-north-1.amazonaws.com/no_background_img.png"
              alt="logo"
              style={{
                maxWidth: 264,
                maxHeight: 74,
                height: "auto",
                marginLeft: -170,
                marginRight: 16,
                flex: 1,
              }}
            />
          </a>
        )}
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            {userId !== "" ? (
              <a
                href="/#/profile"
                style={{
                  display: "flex",
                  alignItems: "center",
                  textDecoration: "none",
                  color: "inherit",
                  marginRight: "20px",
                }}
              >
                <FiUser size={20} />
                <Typography variant="body1" color="inherit" style={{ marginLeft: "8px" }}>
                  {message}
                </Typography>
              </a>
            ) : (
              <a
                href="/#/login"
                style={{
                  display: "flex",
                  alignItems: "center",
                  textDecoration: "none",
                  color: "inherit",
                  marginRight: "20px",
                }}
              >
                <FiUser size={20} />
                <Typography variant="body1" color="inherit" style={{ marginLeft: "8px" }}>
                  {message}
                </Typography>
              </a>
            )}
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <a
              href="https://www.facebook.com/people/Mlad%C3%AD-program%C3%A1tori/100091486973048/"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                display: "flex",
                alignItems: "center",
                textDecoration: "none",
                color: "inherit",
                marginRight: "10px",
              }}
            >
              <FiFacebook size={20} />
            </a>
            <a
              href="https://www.instagram.com/mladiprogramatori/"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                display: "flex",
                alignItems: "center",
                textDecoration: "none",
                color: "inherit",
                marginRight: "15px",
              }}
            >
              <FiInstagram size={20} />
            </a>
            <a
              href="https://www.linkedin.com/in/mladí-programátori-353965271"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                display: "flex",
                alignItems: "center",
                textDecoration: "none",
                color: "inherit",
                marginRight: "10px",
              }}
            >
              <FiLinkedin size={20} />
            </a>
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
