import React from "react";
import {
  Box,
  Paper,
  Typography,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Tooltip,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const tasks = [
  {
    id: 1,
    title: "Úloha 1",
    status: "completed",
    description: "Toto je ukončená úloha.",
    theme: "Základy programovania",
  },
  {
    id: 2,
    title: "Úloha 2",
    status: "active",
    description: "Toto je aktívna úloha. Môžete ju začať riešiť!",
    theme: "Podmienky a cykly",
  },
  {
    id: 3,
    title: "Úloha 3",
    status: "locked",
    description: "Toto je uzamknutá úloha. Bude dostupná neskôr.",
    theme: "Premenné a dátové typy",
  },
  {
    id: 4,
    title: "Úloha 4",
    status: "locked",
    description: "Toto je uzamknutá úloha. Bude dostupná neskôr.",
    theme: "Funkcie a procedúry",
  },
  {
    id: 5,
    title: "Úloha 5",
    status: "locked",
    description: "Toto je uzamknutá úloha. Bude dostupná neskôr.",
    theme: "Rekurzia a iterácia",
  },
  {
    id: 6,
    title: "Úloha 6",
    status: "locked",
    description: "Toto je uzamknutá úloha. Bude dostupná neskôr.",
    theme: "Algoritmy a dátové štruktúry",
  },
  {
    id: 7,
    title: "Úloha 7",
    status: "locked",
    description: "Toto je uzamknutá úloha. Bude dostupná neskôr.",
    theme: "Grafické programovanie",
  },
  {
    id: 8,
    title: "Úloha 8",
    status: "locked",
    description: "Toto je uzamknutá úloha. Bude dostupná neskôr.",
    theme: "Herný vývoj",
  },
  {
    id: 9,
    title: "Úloha 9",
    status: "locked",
    description: "Toto je uzamknutá úloha. Bude dostupná neskôr.",
    theme: "Web development",
  },
  {
    id: 10,
    title: "Úloha 10",
    status: "locked",
    description: "Toto je uzamknutá úloha. Bude dostupná neskôr.",
    theme: "Aplikácie a softvérové projekty",
  },
];

const TasksDisplay: React.FC = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, my: 4, mx: "auto", maxWidth: "600px" }}>
      <Typography variant="h4" component="h2" sx={{ mb: 4 }}>
        Prehľad týždenných úloh
      </Typography>
      {tasks.map((task, index) => (
        <Paper
          elevation={6}
          key={task.id}
          sx={{
            padding: 2,
            mb: 2,
            bgcolor: "background.paper",
            borderRadius: "12px",
            boxShadow: "0 8px 16px rgba(0,0,0,0.1)",
          }}
        >
          <List disablePadding>
            <ListItem>
              <ListItemIcon>
                {task.status === "completed" && <CheckCircleIcon color="success" />}
                {task.status === "active" && <PlayCircleOutlineIcon color="primary" />}
                {task.status === "locked" && <LockIcon color="action" />}
              </ListItemIcon>
              <ListItemText primary={task.title} secondary={task.theme} />
              <Tooltip title={task.description}>
                <IconButton>
                  <HelpOutlineIcon />
                </IconButton>
              </Tooltip>
              <Button
                variant="contained"
                color={task.status === "active" ? "primary" : undefined}
                disabled={task.status === "locked"}
                startIcon={task.status === "completed" ? <CheckCircleIcon /> : null}
                sx={{ mx: 1 }}
              >
                {task.status === "completed" ? "Prehľad" : "Začať"}
              </Button>
            </ListItem>
          </List>
        </Paper>
      ))}
    </Box>
  );
};

export default TasksDisplay;
