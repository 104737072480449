// Main2.tsx
import React from "react";
import { FaGraduationCap, FaClipboardCheck, FaBook, FaUserTie } from "react-icons/fa"; // Importing the required icons
// check mark icon

// icon for town
import { MdLocationOn } from "react-icons/md";
// icon for primary school
import { GiSchoolBag } from "react-icons/gi";
import "./Main2.css";
import Cookies from "js-cookie";

interface Main2Props {
  userId: string;
}

const Main2 = ({ userId }: Main2Props) => {
  const [isLoggedIn, setIsLoggedIn] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (userId) {
      setIsLoggedIn(true);
    }
    // get userId from Cookies
    const userIdCookie = Cookies.get("userId");
    if (userIdCookie) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [userId]);

  return (
    <>
      {!isLoggedIn ? (
        <>
         <div className="container2">
        <div className="content">
          <h1>Krúžky programovania priamo na základných školách</h1>
          <p>
            Chceš sa naučiť programovať z pohodlia <strong>svojej školy?</strong> Vyber si z našej širokej ponuky kurzov
            programovania a pridaj sa k nám! Naše kurzy sú určené pre žiakov, ktorí si chcú rozvíjať logické myslenie a
            pritom sa naučiť základy programovania.
          </p>
          <a href="#/courses">
            <strong>Aktuálna ponuka kurzov</strong>
          </a>
        </div>
      </div>
        <div className="infoBoxContainer">
          <div className="infoBox">
            <MdLocationOn className="icon" />
            <h3>Mestá</h3>
            <p>Sme v 4 mestách na Slovensku</p>
          </div>
          <div className="infoBox">
            <GiSchoolBag className="icon" />
            <h3>Školy</h3>
            <p>Naše kurzy programovania sú na viac ako 20 školách</p>
          </div>
          <div className="infoBox">
            <FaBook className="icon" />
            <h3>Kurzy</h3>
            <p>Široká ponuka kurzov programovania</p>
          </div>
          <div className="infoBox">
            <FaUserTie className="icon" />
            <h3>Lektori</h3>
            <p>Skúsení a odhodlaní lektori</p>
          </div>
          <div className="infoBox">
            <FaClipboardCheck className="icon" />
            <h3>Ukončené kurzy</h3>
            <p>Viac ako 40 ukončených kurzov </p>
          </div>
          <div className="infoBox">
            <FaGraduationCap className="icon" />
            <h3>Absolventi</h3>
            <p>Viac ako 300 úspešných absolventov</p>
          </div>
        </div>
        </>
      ) : (
        <>
         <div className="container22">
        <div className="content22">
          <h1>Krúžky programovania priamo na základných školách</h1>
          <p>
            Chceš sa naučiť programovať z pohodlia <strong>svojej školy?</strong> Vyber si z našej širokej ponuky kurzov
            programovania a pridaj sa k nám! Naše kurzy sú určené pre žiakov, ktorí si chcú rozvíjať logické myslenie a
            pritom sa naučiť základy programovania.
          </p>
          <a href="#/courses">
            <strong>Aktuálna ponuka kurzov</strong>
          </a>
        </div>
      </div>
        <div className="infoBoxContainer2">
          <div className="infoBox2">
            <MdLocationOn className="icon" />
            <h3>Mestá</h3>
            <p>Sme v 4 mestách na Slovensku</p>
          </div>
          <div className="infoBox2">
            <GiSchoolBag className="icon" />
            <h3>Školy</h3>
            <p>Naše kurzy programovania sú na viac ako 20 školách</p>
          </div>
          <div className="infoBox2">
            <FaBook className="icon" />
            <h3>Kurzy</h3>
            <p>Široká ponuka kurzov programovania</p>
          </div>
          <div className="infoBox2">
            <FaUserTie className="icon" />
            <h3>Lektori</h3>
            <p>Skúsení a odhodlaní lektori</p>
          </div>
          <div className="infoBox2">
            <FaClipboardCheck className="icon" />
            <h3>Ukončené kurzy</h3>
            <p>Viac ako 40 ukončených kurzov </p>
          </div>
          <div className="infoBox2">
            <FaGraduationCap className="icon" />
            <h3>Absolventi</h3>
            <p>Viac ako 300 úspešných absolventov</p>
          </div>
        </div>
        </>
      )}
    </>
  );
};


export default Main2;
