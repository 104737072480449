// Main.tsx
import React from "react";
import Main2 from "./Main2";
import Main3 from "./Main3";
// import Contact from './MainContact';
import { FaUsers, FaFileAlt, FaLaptop } from "react-icons/fa"; // Importing the required icons
import "./Main.css";
import { Link } from "react-router-dom";

interface MainProps {
  userId: string;
}

const Main = ({ userId }: MainProps) => {
  return (
    <>
      <Main2 userId={userId} />
      <br />
      <div className="container">
        <div className="header">
          <h1>Kurzy programovania</h1>
          <p>Získajte alebo rozšírte svoje programátorské zručnosti vďaka našej širokej ponuke kurzov programovania!</p>
        </div>

        <div className="courseContainer">
          <div className="courseBox">
            <FaUsers className="icon" />
            <h2>Pre koho sú kurzy určené?</h2>
            <p>
              Kurzy sú určené pre <strong>žiakov základných škôl</strong>, ktorí si chcú rozvíjať logické myslenie a
              pritom sa naučiť zákaldy programovania.
            </p>
            <a href="#/courses">Zobraziť ponuku kurzov</a>
          </div>
          <div className="courseBox">
            <FaFileAlt className="icon" />
            <h2>Kde prebiehajú kurzy?</h2>
            <p>
              Kurzy programovania prebiehajú <strong>priamo na vybraných základných školách</strong>, v miestnosti
              informatiky. Preto Vaše dieťa nemusí nikam cestovať, my prídeme za ním.
            </p>
            <Link to="schools">Zobraziť školy, na ktorých prebiehajú krúžky</Link>
          </div>
          <div className="courseBox">
            <FaLaptop className="icon" />
            <h2>Ako dlho trvá kurz?</h2>
            <p>
              Kurzy trvajú v zásade <strong>celý školský polrok</strong>, (60 min. | 1x týždenne) ak nie je dohoda s
              riaditeľom školy alebo rodičmi iná. Počet študentov na jeden kurz je obmedzený na 10 - 12 účastníkov.
            </p>
            <Link to="coursesWork">Viac informácií o kurzoch</Link>
          </div>
        </div>
      </div>
      <br></br>
      <div className="container5">
        <Main3 />
      </div>
      <br></br>
      {/* <div className="container6">
      <Contact />
    </div> */}
      <br></br>
    </>
  );
};

export default Main;
