import React, { useEffect, useState } from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import "./Main3.css";

type BoxData = {
  title: string;
  text: string;
};

const boxes: BoxData[] = [
  {
    title: "Prečo práve naše kurzy?",
    text: "Naše kurzy programovania sú jedinečné tým, že sú vykonávané priamo na školách, v miestnostiach informatiky. Preto Vaše dieťa nemusí nikam cestovať, my prídeme za ním.",
  },
  {
    title: "Cena nášho kurzu",
    text: "Cena sa odvíja od mesta, v ktorom sa nachádza ZŠ žiaka, od poplatku za prenájom miestnosti informatiky a od obsadenosti žiakmi.",
  },
  {
    title: "Ako sa prihlásiť?",
    text: "Prihláška je veľmi jednoduchá. Stačí sa prihlásiť na našej stránke do svojho účtu a následne vyplniť prihlášku na kurz.",
  },
  {
    title: "Čo potrebujete?",
    text: "Na účasť v kurze nie je potrebné žiadne špeciálne vybavenie. Stačí, ak Vaše dieťa príde na lekciu s dobrou náladou a chuťou učiť sa nové veci. Všetky potrebné pomôcky a techniku zabezpečíme my.",
  },
];

const Main3: React.FC = () => {
  const [activeBoxIndex, setActiveBoxIndex] = useState(0);

  const prevBox = () => {
    setActiveBoxIndex((oldIndex) => (oldIndex > 0 ? oldIndex - 1 : boxes.length - 1));
  };

  const nextBox = () => {
    setActiveBoxIndex((oldIndex) => (oldIndex < boxes.length - 1 ? oldIndex + 1 : 0));
  };

  // each 2 seconds, the next box will be shown
  useEffect(() => {
    const interval = setInterval(() => {
      nextBox();
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="container3">
      <NavigateBeforeIcon className="navigation-icon2" onClick={prevBox} />
      <SwitchTransition>
        <CSSTransition key={activeBoxIndex} timeout={500} classNames="box-transition">
          <div className="box">
            <h2>{boxes[activeBoxIndex].title}</h2>
            <p>{boxes[activeBoxIndex].text}</p>
          </div>
        </CSSTransition>
      </SwitchTransition>
      <NavigateNextIcon className="navigation-icon2" onClick={nextBox} />
    </div>
  );
};

export default Main3;
