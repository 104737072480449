import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import NotLoggedIn from "../Login/NotLoggedIn";
import { useAuth } from "../Auth/AuthContext";
import Cookie from "js-cookie";
import "./style/adminCourse.css";
import { formatDate } from "../../utils/date";

interface Course {
  _id: string;
  time: string;
  language: string;
  startDate: string;
  endDate: string;
  lectorId: string;
  schoolName: string;
  lectorName: string;
  monthPayment: number;
  capacity: number;
  schoolId: string;
  childrens: string[];
  attendIds: string[];
  paymentActive: boolean;
}

interface Lector {
  _id: string;
  name: string;
  surname: string;
}

interface AdminCourseProps {
  userId: string;
}

const AdminCourse = ({ userId }: AdminCourseProps) => {
  const [courses, setCourses] = useState<Course[]>([]);
  const [lectors, setLectors] = useState<Lector[]>([]);
  const [editedCourse, setEditedCourse] = useState<Course | null>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [newCourse, setNewCourse] = useState<Course>({
    _id: "",
    time: "",
    language: "Python",
    startDate: "",
    endDate: "",
    lectorId: "",
    schoolName: "",
    lectorName: "",
    monthPayment: 0,
    capacity: 0,
    schoolId: "",
    childrens: [],
    attendIds: [],
    paymentActive: false,
  });
  const [schoolNames, setSchoolNames] = useState<string[]>([]);
  const [schools, setSchools] = useState<any[]>([]);
  const { token } = useAuth();

  const url =
    process.env.REACT_APP_ENVIRONMENT === "prod" ? process.env.REACT_APP_PROD_URL : process.env.REACT_APP_DEV_URL;

  useEffect(() => {
    if (!userId) return;

    const fetchData = async () => {
      try {
        const [coursesResponse, lectorsResponse, schoolsResponse] = await Promise.all([
          fetch(url + "course/allCourses", {
            headers: { Authorization: `Bearer ${token}`, "X-User-Id": userId },
          }),
          fetch(url + "lector/allLectors", {
            headers: { Authorization: `Bearer ${token}`, "X-User-Id": userId },
          }),
          fetch(url + "school/allSchools", {
            headers: {
              Authorization: `Bearer ${token}`,
              "X-User-Id": userId,
              "X-Public-Key": process.env.REACT_APP_JWT_SECRET || "",
            },
          }),
        ]);

        if (!coursesResponse.ok || !lectorsResponse.ok || !schoolsResponse.ok) {
          throw new Error("Failed to fetch data");
        }

        const coursesData = await coursesResponse.json();
        const lectorsData = await lectorsResponse.json();
        const schoolsData = await schoolsResponse.json();

        setCourses(coursesData.courses);
        setLectors(lectorsData);
        setSchools(schoolsData.schools);
        setSchoolNames(schoolsData.schools.map((school: any) => school.name));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [token, url, userId]);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setEditedCourse((prev) => prev && { ...prev, [name]: value });
    },
    []
  );

  const handleSaveChanges = async () => {
    if (!editedCourse) return;
    try {
      const response = await fetch(url + `course/${editedCourse._id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-User-Id": userId,
        },
        body: JSON.stringify(editedCourse),
      });
      if (!response.ok) throw new Error("Failed to update course");
      setCourses((prev) => prev.map((course) => (course._id === editedCourse._id ? editedCourse : course)));
      setIsEditing(false);
      setEditedCourse(null);
    } catch (error) {
      console.error("Error updating course:", error);
    }
  };

  const handleCreateCourse = async () => {
    try {
      const schoolPost = schools.find((school) => school.name === newCourse.schoolName);
      newCourse.schoolId = schoolPost?._id;

      const lectorPost = lectors.find((lector) => lector._id === newCourse.lectorId);
      newCourse.lectorName = lectorPost ? `${lectorPost.name} ${lectorPost.surname}` : "";

      const response = await fetch(url + "course/register", {
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}`, "X-User-Id": userId },
        body: JSON.stringify(newCourse),
      });

      if (!response.ok) throw new Error("Failed to create course");

      const data = await response.json();
      setCourses((prev) => [...prev, data.course]);

      setNewCourse({
        _id: "",
        time: "",
        language: "Python",
        startDate: "",
        endDate: "",
        lectorId: "",
        schoolName: "",
        lectorName: "",
        monthPayment: 0,
        capacity: 0,
        schoolId: "",
        childrens: [],
        attendIds: [],
        paymentActive: true,
      });
    } catch (error) {
      console.error("Error creating course:", error);
    }
  };

  if (!token && !Cookie.get("token")) return <NotLoggedIn />;

  return (
    <div className="admin-course-container">
      <h3>Add new Course</h3>
      <div className="new-course-form">
        <label htmlFor="name">Course Name</label>
        <input
          type="text"
          value={newCourse.time}
          onChange={(e) => setNewCourse({ ...newCourse, time: e.target.value })}
          placeholder="Course Name"
          className="input-field"
        />
        <label htmlFor="language">Language:</label>
        <select
          value={newCourse.language}
          onChange={(e) => setNewCourse({ ...newCourse, language: e.target.value })}
          className="input-field"
        >
          <option value="Python">Python</option>
          <option value="Scratch">Scratch</option>
          <option value="Roblox">Roblox</option>
        </select>
        <label htmlFor="startDate">Start Date:</label>
        <input
          type="date"
          value={newCourse.startDate}
          onChange={(e) => setNewCourse({ ...newCourse, startDate: e.target.value })}
          className="input-field"
        />
        <label htmlFor="endDate">End Date:</label>
        <input
          type="date"
          value={newCourse.endDate}
          onChange={(e) => setNewCourse({ ...newCourse, endDate: e.target.value })}
          className="input-field"
        />
        <label htmlFor="monthPayment">Month Payment:</label>
        <input
          type="number"
          value={newCourse.monthPayment}
          onChange={(e) => setNewCourse({ ...newCourse, monthPayment: parseFloat(e.target.value) })}
          placeholder="Month Payment"
          className="input-field"
        />
        <label htmlFor="capacity">Capacity:</label>
        <input
          type="number"
          value={newCourse.capacity}
          onChange={(e) => setNewCourse({ ...newCourse, capacity: parseInt(e.target.value) })}
          placeholder="Capacity"
          className="input-field"
        />
        <label htmlFor="schoolName">School Name:</label>
        <select
          value={newCourse.schoolName}
          onChange={(e) => setNewCourse({ ...newCourse, schoolName: e.target.value })}
          className="input-field"
        >
          <option value="" disabled>
            Select School
          </option>
          {schoolNames &&
            schoolNames.map((schoolName) => (
              <option key={schoolName} value={schoolName}>
                {schoolName}
              </option>
            ))}
        </select>
        <label htmlFor="lectorId">Lector:</label>
        <select
          value={newCourse.lectorId}
          onChange={(e) => setNewCourse({ ...newCourse, lectorId: e.target.value })}
          className="input-field"
        >
          <option value="" disabled>
            Select Lector
          </option>
          {lectors &&
            lectors.map((lector) => (
              <option key={lector._id} value={lector._id}>
                {lector.name + " " + lector.surname}
              </option>
            ))}
        </select>
        <button className="create-button" onClick={handleCreateCourse}>
          Create Course
        </button>
      </div>

      <div className="course-list">
        <h3>List of Courses</h3>
        <table>
          <thead>
            <tr>
              <th>Time</th>
              <th>Language</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Course ID</th>
              <th>School Name</th>
              <th>Month Payment</th>
              <th>Capacity</th>
              <th>Students</th>
              <th>Free Places</th>
              <th>Edit</th>
              <th>Course Details</th>
            </tr>
          </thead>
          <tbody>
            {courses.map((course) => (
              <tr key={course._id}>
                <td>
                  {isEditing && editedCourse?._id === course._id ? (
                    <input type="text" name="time" value={editedCourse.time} onChange={handleInputChange} />
                  ) : (
                    course.time
                  )}
                </td>
                <td>
                  {isEditing && editedCourse?._id === course._id ? (
                    <select name="language" value={editedCourse.language} onChange={handleInputChange}>
                      <option value="Python">Python</option>
                      <option value="Scratch">Scratch</option>
                      <option value="Roblox">Roblox</option>
                    </select>
                  ) : (
                    course.language
                  )}
                </td>
                <td>
                  {isEditing && editedCourse?._id === course._id ? (
                    <input type="date" name="startDate" value={editedCourse.startDate} onChange={handleInputChange} />
                  ) : (
                    formatDate(course.startDate)
                  )}
                </td>
                <td>
                  {isEditing && editedCourse?._id === course._id ? (
                    <input type="date" name="endDate" value={editedCourse.endDate} onChange={handleInputChange} />
                  ) : (
                    formatDate(course.endDate)
                  )}
                </td>
                <td>{course._id}</td>
                <td>{course.schoolName}</td>
                <td>
                  {isEditing && editedCourse?._id === course._id ? (
                    <input
                      type="number"
                      name="monthPayment"
                      value={editedCourse.monthPayment}
                      onChange={handleInputChange}
                    />
                  ) : (
                    course.monthPayment
                  )}
                </td>
                <td>
                  {isEditing && editedCourse?._id === course._id ? (
                    <input type="number" name="capacity" value={editedCourse.capacity} onChange={handleInputChange} />
                  ) : (
                    course.capacity
                  )}
                </td>
                <td>{course.childrens.length}</td>
                <td>{course.capacity - course.childrens.length}</td>
                <td>
                  {isEditing && editedCourse?._id === course._id ? (
                    <button onClick={handleSaveChanges}>Save</button>
                  ) : (
                    <button
                      onClick={() => {
                        setEditedCourse(course);
                        setIsEditing(true);
                      }}
                    >
                      Edit
                    </button>
                  )}
                </td>
                <td>
                  <Link to={`/course/details/${course._id}`}>
                    <button>Course Details</button>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminCourse;
