import React, { useState } from "react";
import { Paper, Typography, Box, Collapse, IconButton, Tooltip } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import ComputerIcon from "@mui/icons-material/Computer"; // Icon representing programming
import { keyframes } from "@emotion/react";

// Keyframe animations for the component
const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const slideDown = keyframes`
  from { transform: translateY(-10px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
`;

const ChallengeInfo: React.FC = () => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Paper elevation={2} sx={{ padding: "20px", backgroundColor: "#f3f3f3", animation: `${fadeIn} 0.8s` }}>
      <Typography variant="h5" sx={{ marginBottom: "12px", display: "flex", alignItems: "center" }}>
        Prehľad týždennej výzvy
        <Tooltip title="Viac informácií">
          <IconButton sx={{ marginLeft: "10px" }}>
            <InfoIcon color="action" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Programovací jazyk Scratch">
          <IconButton sx={{ marginLeft: "10px" }}>
            <ComputerIcon />
          </IconButton>
        </Tooltip>
      </Typography>
      <Typography variant="body1" sx={{ animation: `${slideDown} 1s` }}>
        Vitajte v tomto mesačnom programovacom výzve! Toto je príležitosť otestovať vaše zručnosti a naučiť sa nové
        techniky. Každá výzva sa zameriava na rôzne aspekty programovania.
      </Typography>
      <Box mt={2}>
        <Typography variant="body2">Dátum začiatku: 1. oktober 2024</Typography>
        <Typography variant="body2">Dátum konca: 31. januar 2025</Typography>
        <Typography variant="body2">Náročnosť: Začiatočník</Typography>
        <Typography variant="body2">Programovací jazyk: Scratch</Typography>
        <Typography variant="body2">Úloha: 1 z 18</Typography>
        <Typography variant="body2">Čas na spracovanie: 1 týždeň</Typography>
      </Box>
      <IconButton
        onClick={handleExpandClick}
        sx={{ transition: "transform 0.2s", transform: expanded ? "rotate(180deg)" : "rotate(0)" }}
      >
        <ExpandMoreIcon />
      </IconButton>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Box sx={{ mt: 2, animation: `${slideDown} 1s` }}>
          <Typography variant="body2" paragraph>
            Témy a zručnosti:
          </Typography>
          <Typography variant="body2">- Návrh algoritmov</Typography>
          <Typography variant="body2">- Techniky ladenia</Typography>
          <Typography variant="body2">- Dátové štruktúry</Typography>
          <Typography variant="body2" paragraph>
            - Zásady dizajnu UI/UX
          </Typography>
          <Typography variant="body2">Ocenenia:</Typography>
          <Typography variant="body2">- Certifikáty o úspechu</Typography>
          <Typography variant="body2">- Ceny pre najlepších prispievateľov</Typography>
          <Typography variant="body2">- Uvedenie na našej webovej stránke</Typography>
        </Box>
      </Collapse>
    </Paper>
  );
};

export default ChallengeInfo;
