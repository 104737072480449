import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  Menu as MenuIcon,
  Book as BookIcon,
  School as SchoolIcon,
  People as PeopleIcon,
  Call as CallIcon,
  Info as InfoIcon,
  Autorenew as ProgressIcon,
  Assignment as RegistrationIcon,
  Dashboard as DashboardIcon,
  Event as EventIcon,
  // online courses
  OnlinePredictionOutlined as OnlinePredictionOutlinedIcon,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/system";
import { useLocation } from "react-router-dom";

import "./SubHeader.css";

interface SubheaderProps {
  title: string;
}

const menuItems = [
  { link: "/", text: "Hlavná stránka", icon: <DashboardIcon /> },
  { link: "/courses", text: "Ponuka kurzov", icon: <BookIcon /> },
  { link: "/coursesWork", text: "Ako prebieha kurz", icon: <SchoolIcon /> },
  { link: "/schools", text: "Školy", icon: <PeopleIcon /> },
  { link: "/registration-circle", text: "Postup registrácie", icon: <ProgressIcon /> },
  { link: "/weekly-challenge-overview", text: "Týždenné úlohy", icon: <EventIcon /> },
  { link: "/course-interest", text: "Predbežný záujem", icon: <RegistrationIcon /> },
  { link: "/online-courses", text: "Online kurzy", icon: <OnlinePredictionOutlinedIcon /> },
  { link: "/contact", text: "Kontakt", icon: <CallIcon /> },
  { link: "/about-us", text: "O nás", icon: <InfoIcon /> },
];

const SubHeader = ({ title }: SubheaderProps) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [fixedSubheader, setFixedSubheader] = useState(false); // State to control subheader fixed position
  const location = useLocation();

  const updatedMenuItems =
    location.pathname.endsWith("/#") || location.pathname.endsWith("/#/") || location.pathname.endsWith("/")
      ? menuItems.filter((item) => item.link !== "/")
      : menuItems;

  useEffect(() => {
    const handleScroll = () => {
      // Here, 80 represents the height of the main header; adjust if needed.
      if (window.scrollY > 80) {
        setFixedSubheader(true);
      } else {
        setFixedSubheader(false);
      }
    };

    // Attach the scroll listener
    window.addEventListener("scroll", handleScroll);

    // Cleanup function to remove the listener when the component is unmounted
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    const mainHeader = document.querySelector("#main-header");
    const subheader = document.getElementById("subheader");
    let timeoutId: string | number | NodeJS.Timeout | undefined;

    if (mainHeader && subheader) {
      const observer = new IntersectionObserver((entries) => {
        if (!entries[0].isIntersecting) {
          // Clear any existing timeouts to ensure we don't have multiple running
          if (timeoutId) {
            clearTimeout(timeoutId);
          }
          // Delay the slide down by 2 seconds (2000 milliseconds)
          timeoutId = setTimeout(() => {
            subheader.className = "slideDown";
          }, 200);
        } else {
          subheader.className = "slideOff";
        }
      });

      observer.observe(mainHeader);

      // Cleanup function
      return () => {
        observer.unobserve(mainHeader);
        // Clear the timeout when the component unmounts
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
      };
    }
  }, []);

  const drawer = (
    <div>
      <List>
        {updatedMenuItems.map((item, index) => (
          <ListItem button key={index} component={Link} to={item.link} onClick={handleDrawerToggle}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <>
      {fixedSubheader && <div className="subheader-placeholder"></div>}
      <div id="subheader" className={fixedSubheader ? "slideDown" : "slideOff"}>
        <AppBar position="static" sx={{ backgroundColor: "#FFFFFF" }}>
          <Toolbar>
            {isMobile ? (
              <>
                <IconButton
                  color="info"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{ mr: 2, display: { xs: "block", sm: "none" } }}
                >
                  <MenuIcon />
                </IconButton>
                <Drawer anchor="left" open={mobileOpen} onClose={handleDrawerToggle}>
                  {drawer}
                </Drawer>
              </>
            ) : (
              <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", width: "100%" }}>
                {updatedMenuItems.map((item, index) => (
                  <Link
                    key={index}
                    to={item.link}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      textDecoration: "none",
                      color: "black",
                      marginLeft: "10px",
                    }}
                  >
                    {item.icon}
                    <Typography
                      variant="body2"
                      color="inherit"
                      sx={{ marginLeft: "10px", fontWeight: "bold", fontSize: "16px", marginRight: "20px" }}
                    >
                      {item.text}
                    </Typography>
                  </Link>
                ))}
              </div>
            )}
          </Toolbar>
        </AppBar>
      </div>
    </>
  );
};

export default SubHeader;
