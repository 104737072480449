import React, { useState, useRef } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
  Grid,
  Snackbar,
  Alert,
  Collapse,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
  SelectChangeEvent,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { courses } from "./coursesData";
import "./Online.css";

const Online: React.FC = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");
  const [selectedCourse, setSelectedCourse] = useState("");
  const [expandedCourse, setExpandedCourse] = useState<number | null>(null);
  const [formValues, setFormValues] = useState({
    studentName: "",
    parentContact: "",
    email: "",
    class: "",
    schoolName: "",
    preferredDay: "",
    preferredTime: "",
    programmingLanguage: "",
    agreeToTerms: false,
  });

  const formRef = useRef<HTMLDivElement | null>(null);

  const customAlert = (msg: string, severity: "success" | "error" = "success") => {
    setSnackbarMessage(msg);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name as keyof typeof formValues]: value });
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({ ...formValues, agreeToTerms: e.target.checked });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // if (
    //   !formValues.studentName ||
    //   !formValues.parentContact ||
    //   !formValues.email ||
    //   !formValues.class ||
    //   !formValues.schoolName ||
    //   !formValues.preferredDay ||
    //   !formValues.preferredTime ||
    //   !formValues.programmingLanguage ||
    //   !formValues.agreeToTerms
    // ) {
    //   customAlert("Prosím, vyplňte všetky polia.", "error");
    //   return;
    // }

    const url =
      process.env.REACT_APP_ENVIRONMENT === "prod" ? process.env.REACT_APP_PROD_URL : process.env.REACT_APP_DEV_URL;

    const response = await fetch(url + "mail/send-email-interest", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        course: selectedCourse,
        ...formValues,
      }),
    });

    if (response.ok) {
      customAlert("Predbežný záujem bol úspešne odoslaný!", "success");
      setFormValues({
        studentName: "",
        parentContact: "",
        email: "",
        class: "",
        schoolName: "",
        preferredDay: "",
        preferredTime: "",
        programmingLanguage: "",
        agreeToTerms: false,
      });
    } else {
      customAlert("Nepodarilo sa odoslať predbežný záujem.", "error");
    }
  };

  const handleCourseSelect = (courseTitle: string, programmingLanguage: string) => {
    setSelectedCourse(courseTitle);
    setFormValues((prev) => ({
      ...prev,
      programmingLanguage,
    }));

    // customAlert(`Vybraný kurz: ${courseTitle}`, "success");

    // Scroll to the form if it's a mobile device
    if (window.innerWidth <= 768 && formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const toggleExpandedCourse = (index: number) => {
    setExpandedCourse(expandedCourse === index ? null : index);
  };

  return (
    <div>
      <Card className="custom-card">
        <CardContent>
          <Typography variant="h4" component="h1" className="card-title">
            Nemôže sa váš žiak zúčastniť fyzických kurzov? Máme riešenie!
          </Typography>
          <Typography variant="h6" component="h2" className="card-subtitle">
            Obľúbené online kurzy pre všetky vekové kategórie, kdekoľvek a kedykoľvek.
          </Typography>
        </CardContent>
      </Card>

      <Grid container spacing={3} sx={{ padding: "20px" }}>
        {courses.map((course, index) => (
          <Grid item xs={12} md={4} key={index}>
            <Card sx={{ height: "100%" }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  {course.title}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {course.description}
                </Typography>
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={() => toggleExpandedCourse(index)}
                  sx={{ marginBottom: "20px" }}
                  endIcon={expandedCourse === index ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                >
                  {expandedCourse === index ? "Zavrieť" : "Zobraziť viac informácií"}
                </Button>

                <Collapse in={expandedCourse === index} timeout="auto" unmountOnExit>
                  <Typography variant="body2">
                    <strong>Náročnosť:</strong> {course.difficulty}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Trvanie:</strong> {course.duration}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Kapacita:</strong> {course.capacity}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Vhodné pre:</strong> {course.suitableFor}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Podmienky:</strong> {course.prerequisites}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Cena:</strong> {course.price}
                  </Typography>
                  <Typography variant="body2" sx={{ marginTop: "10px" }}>
                    <strong>Ako kurz prebieha:</strong> {course.howItWorks}
                  </Typography>
                  <Typography variant="body2" sx={{ marginTop: "10px" }}>
                    <strong>Recenzie:</strong>
                    <ul>
                      {course.reviews.map((review, i) => (
                        <li key={i}>
                          <strong>{review.user}:</strong> {review.comment}
                        </li>
                      ))}
                    </ul>
                  </Typography>
                </Collapse>

                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    fontSize: "1.1em",
                    backgroundColor: "#3f51b5",
                    color: "white",
                    marginTop: "20px",
                    "&:hover": {
                      backgroundColor: "#303f9f",
                    },
                  }}
                  onClick={() => handleCourseSelect(course.title, course.language)}
                >
                  Vybrať kurz
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {selectedCourse && (
        <div ref={formRef}>
          <Card sx={{ maxWidth: 800, margin: "20px auto", padding: "20px" }}>
            <CardContent>
              <Typography variant="h5" component="h2" gutterBottom sx={{ fontSize: "2em", marginBottom: "20px" }}>
                Záujem o kurz programovania - {selectedCourse}
              </Typography>
              <Typography variant="body1" gutterBottom>
                Nevyhovuje vám termín kurzu alebo sa Vám neušlo miesto? Zaregistrujte sa do zoznamu záujemcov a my vás
                budeme informovať o nových termínoch. V prípade uvoľnenia kapacity kurzu ako aj v prípade novo
                otvoreného kurzu vás budeme informovať.
              </Typography>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      id="email"
                      name="email"
                      label="Mail rodiča"
                      variant="outlined"
                      fullWidth
                      required
                      value={formValues.email}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="studentName"
                      name="studentName"
                      label="Meno dieťaťa"
                      variant="outlined"
                      fullWidth
                      required
                      value={formValues.studentName}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="class"
                      name="class"
                      label="Trieda dieťaťa"
                      variant="outlined"
                      fullWidth
                      required
                      value={formValues.class}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  {/* <Grid item xs={12}>
                    <TextField
                      id="schoolName"
                      name="schoolName"
                      label="Názov školy"
                      variant="outlined"
                      fullWidth
                      required
                      value={formValues.schoolName}
                      onChange={handleInputChange}
                    />
                  </Grid> */}
                  <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined" required>
                      <InputLabel id="programmingLanguage-label">Programovací jazyk</InputLabel>
                      <Select
                        labelId="programmingLanguage-label"
                        id="programmingLanguage"
                        name="programmingLanguage"
                        value={formValues.programmingLanguage}
                        onChange={handleSelectChange}
                        label="Programovací jazyk"
                      >
                        <MenuItem value="Scratch">Scratch</MenuItem>
                        <MenuItem value="Lua">Lua</MenuItem>
                        <MenuItem value="Python">Python</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth variant="outlined" required>
                      <InputLabel id="preferredDay-label">Preferovaný deň</InputLabel>
                      <Select
                        labelId="preferredDay-label"
                        id="preferredDay"
                        name="preferredDay"
                        value={formValues.preferredDay}
                        onChange={handleSelectChange}
                        label="Preferovaný deň"
                      >
                        <MenuItem value="Pondelok">Pondelok</MenuItem>
                        <MenuItem value="Utorok">Utorok</MenuItem>
                        <MenuItem value="Streda">Streda</MenuItem>
                        <MenuItem value="Štvrtok">Štvrtok</MenuItem>
                        <MenuItem value="Piatok">Piatok</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth variant="outlined" required>
                      <InputLabel id="preferredTime-label">Preferovaný čas</InputLabel>
                      <Select
                        labelId="preferredTime-label"
                        id="preferredTime"
                        name="preferredTime"
                        value={formValues.preferredTime}
                        onChange={handleSelectChange}
                        label="Preferovaný čas"
                      >
                        <MenuItem value="15:00">15:00</MenuItem>
                        <MenuItem value="16:00">16:00</MenuItem>
                        <MenuItem value="17:00">17:00</MenuItem>
                        <MenuItem value="18:00">18:00</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formValues.agreeToTerms}
                          onChange={handleCheckboxChange}
                          name="agreeToTerms"
                          color="primary"
                        />
                      }
                      label={
                        <>
                          Súhlasím so spracovaním osobných údajov v súlade so{" "}
                          <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
                            zásadami ochrany osobných údajov
                          </a>
                        </>
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      type="submit"
                      fullWidth
                      sx={{
                        fontSize: "1.1em",
                        backgroundColor: "#3f51b5",
                        color: "white",
                        "&.Mui-disabled": {
                          backgroundColor: "#3f51b5",
                          color: "white",
                        },
                      }}
                    >
                      ODOSLAŤ
                    </Button>
                  </Grid>
                </Grid>
              </form>
              <Typography variant="body2" color="error" align="center" sx={{ marginTop: "20px" }}>
                Táto prihláška slúži na informačné účely. Pre plnohodnotné prihlásenie žiaka na kurz je potrebné
                prihlásenie sa do aplikácie.
              </Typography>
            </CardContent>
          </Card>
        </div>
      )}

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Online;
