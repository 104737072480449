import React from "react";
import "./AboutUs.css";

const AboutUs: React.FC = () => {
  return (
    <div className="about-us">
      <h1>Vitajte na stránke Mladí programátori</h1>
      <p>
        Sme skupina nadšených pedagógov a študentov, ktorí sa snažia vychovávať budúcu generáciu vývojárov. Zo skúseností vieme, že programovanie je skvelý spôsob, ako rozvíjať kreativitu, logické myslenie a riešiť problémy. Počas
        našej existencie sme sa stretli s mnohými mladými žiakmo, ktorí sú rovnako nadšení z programovania ako my.
      </p>
      <p>
        Naša filozofia je jednoduchá: Programovanie by malo byť zábavné, prístupné a inšpirujúce. Veríme, že vďaka
        technológii máme moc zmeniť svet k lepšiemu a chceme, aby naši študenti boli na čele tejto zmeny.
      </p>
      <p>
        Za posledné desaťročie sa svet technológie neustále vyvíjal rýchlosťou blesku. Uprostred tejto technologickej
        revolúcie sme sa zrodili ako Mladí Programátori. Naša vášeň pre programovanie a naše odhodlanie zdieľať tieto
        zručnosti s mladou generáciou nás viedli k tomu, aby sme sa stali jedným z popredných výchovných centier v
        oblasti IT na Slovensku.
      </p>
      <p>
        Naše tímy pedagógov sú zložené z profesionálnych programátorov, študentov informatiky, ktorí pracovali na
        globálnych technologických projektoch. Každý z nich prinesie do triedy bohaté skúsenosti a osobné príbehy, ktoré
        inšpirujú a motivujú študentov.
      </p>
      <p>
        Taktiež sme hrdí na našu komunitu bývalých študentov, ktorí teraz pracujú v popredných technologických firmách
        po celom svete. Naša alumni sieť je aktívna a angažovaná, často sa vracia, aby prednášala, mentovala a pomáhala
        smerovať našu víziu do budúcnosti.
      </p>
      <p>
        V Mladých Programátoroch veríme, že vzdelávanie je neustály proces. Naše platformy poskytujú študentom
        prístup k množstvu zdrojov, tutoriálov a podporných komunít, kde môžu pokračovať vo svojom vzdelávaní mimo
        triedy. Snažíme sa byť nielen miestom výučby, ale aj komunitou, kde sa študenti môžu cítiť podporovaní a
        inšpirovaní.
      </p>
      <p>
        Na záver by sme radi povedali, že naša cesta ešte len začína. V dobe, keď sa technológia stáva stredobodom
        našich životov, je naším cieľom prispieť k tomu, aby mladá generácia bola pripravená na výzvy budúcnosti.
      </p>
      <p>
        Používaním našej stránky súhlasíte s našimi <a href="#/cookiePolicy">zásadami používania súborov cookie</a>.
        Používateľov, ktorí sa rozhodnú obísť podmienky, upozorňujeme, že niektoré funkcie našej stránky môžu byť
        obmedzené. Viac informácií o <a href="#/terms-and-conditions">všeobecných obchodných podmienkach</a>.
      </p>
    </div>
  );
};

export default AboutUs;
