import { Tooltip } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./CourseDetailsUser.css";
import InfoIcon from "@mui/icons-material/Info";
import NotLoggedIn from "../Login/NotLoggedIn";
import { formatDate } from "../../utils/date";

interface Course {
  _id: string;
  time: string;
  language: string;
  startDate: string;
  endDate: string;
  lectorId: string;
  schoolName: string;
  lectorName: string;
  monthPayment: number;
  capacity: number;
  schoolId: string;
  childrens: string[];
  attendIds: string[];
}

const CourseDetailsUser: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [course, setCourse] = useState<Course | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedCourse, setEditedCourse] = useState<Course | null>(null);
  const [showTooltip, setShowTooltip] = useState(false);

  const [clicked, setClicked] = useState(false);

  const handleIconClick = () => {
    if (!clicked) {
      setShowTooltip(true);
    } else {
      setShowTooltip(false);
    }
    setClicked(!clicked);
  };

  const url =
    process.env.REACT_APP_ENVIRONMENT === "prod" ? process.env.REACT_APP_PROD_URL : process.env.REACT_APP_DEV_URL;

  useEffect(() => {
    const fetchCourseDetails = async () => {
      try {
        const response = await fetch(url + `course/${id}`, {
          headers: {
            "Content-Type": "application/json",
            "X-Public-Key": process.env.REACT_APP_JWT_SECRET || "",
          },
        });
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        const data = await response.json();
        setCourse(data.course);
      } catch (error) {
        console.error("Error fetching course details:", error);
      }
    };
    fetchCourseDetails();
  }, [id, url]);

  const handleSaveClick = async () => {
    try {
      const response = await fetch(url + `course/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(editedCourse),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      setIsEditing(false);
      setCourse(editedCourse);
    } catch (error) {
      console.error("Error updating course:", error);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setEditedCourse((prevCourse) => ({
      ...(prevCourse as Course),
      [name]: value,
    }));
  };

  if (!id) {
    return <NotLoggedIn />;
  }

  // function displayNameShortened(fullName: string) {
  //   let parts = fullName.split(' ');
  //   if (parts.length >= 2) {
  //     return `${parts[0]} ${parts[1].charAt(0)}.`;
  //   } else {
  //     return fullName; // fallback to the full name if there's no space
  //   }
  // }

  return (
    <div className="nieco">
      <div className="course-details-container">
        {course ? (
          <>
            <h2>Detaily krúžku</h2>
            <div className="course-details">
              {isEditing ? (
                <>
                  <span className="course-info">
                    Language:
                    <input
                      type="text"
                      name="language"
                      value={editedCourse?.language || ""}
                      onChange={handleInputChange}
                      className="course-info-edit"
                    />
                  </span>
                  <span className="course-info">
                    Start date:
                    <input
                      type="text"
                      name="startDate"
                      value={editedCourse?.startDate || ""}
                      onChange={handleInputChange}
                      className="course-info-edit"
                    />
                  </span>
                  <span className="course-info">
                    End date:
                    <input
                      type="text"
                      name="endDate"
                      value={editedCourse?.endDate || ""}
                      onChange={handleInputChange}
                      className="course-info-edit"
                    />
                  </span>
                  <span className="course-info">
                    Lector:
                    <input
                      type="text"
                      name="lectorId"
                      value={editedCourse?.lectorId || ""}
                      onChange={handleInputChange}
                      className="course-info-edit"
                    />
                  </span>
                  <button onClick={handleSaveClick}>Save</button>
                </>
              ) : (
                <>
                  <span className="course-info">Čas: {course.time}</span>
                  <span className="course-info">Programovací jazyk: {course.language}</span>
                  <span className="course-info">Začiatok: {formatDate(course.startDate)}</span>
                  <span className="course-info">Koniec: {formatDate(course.endDate)}</span>
                  <span className="course-info">Kapacita krúžku: {course.capacity > 12 ? "12" : course.capacity}</span>
                  {/* <span className="course-info">Počet voľných miest: {(course.capacity - course.childrens.length > 12 ? '10' : course.capacity - course.childrens.length)}</span> */}
                  <span className="course-info">
                    Cena: {course.monthPayment - 0.5} €/hod
                    <span
                      onMouseEnter={() => setShowTooltip(true)}
                      onMouseLeave={() => setShowTooltip(false)}
                      onClick={handleIconClick}
                      onTouchStart={() => setShowTooltip(true)}
                      onTouchEnd={() => setShowTooltip(false)}
                    >
                      {showTooltip && (
                        <Tooltip title="Cena sa odvíja od mesta, poplatku za prenájom a obsadenosti" open>
                          <InfoIcon className="info-icon" style={{ marginLeft: "8px", verticalAlign: "middle" }} />
                        </Tooltip>
                      )}
                      {!showTooltip && (
                        <InfoIcon className="info-icon" style={{ marginLeft: "8px", verticalAlign: "middle" }} />
                      )}
                    </span>
                  </span>
                  <span className="course-info">Škola: {course.schoolName}</span>
                  {/* <span className="course-info">Meno lektora: {displayNameShortened(course.lectorName)}</span> */}
                </>
              )}
            </div>
          </>
        ) : (
          <div>Loading...</div>
        )}
      </div>
    </div>
  );
};

export default CourseDetailsUser;
