import React, { useState } from "react";
import { Card, CardContent, Typography, Button, TextField, Grid } from "@mui/material";
import { Snackbar, Alert } from "@mui/material";

const Contact: React.FC = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");

  const customAlert = (msg: string, severity: "success" | "error" = "success") => {
    setSnackbarMessage(msg);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!name || !email || !message) {
      customAlert("Prosím, vyplňte všetky polia.", "error");
      return; // Early return to prevent further execution
    }

    const url =
      process.env.REACT_APP_ENVIRONMENT === "prod" ? process.env.REACT_APP_PROD_URL : process.env.REACT_APP_DEV_URL;

    const response = await fetch(url + "mail/send-email", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email, // Parent's email
        subject: `Správa od ${name}`,
        html: `
          <p><strong>Meno:</strong> ${name}</p>
          <p><strong>Email:</strong> ${email}</p>
          <p><strong>Správa:</strong></p>
          <p>${message}</p>
        `,
      }),
    });

    if (response.ok) {
      customAlert("Email úspešne odoslaný!", "success");
      setName("");
      setEmail("");
      setMessage("");
    } else {
      customAlert("Nepodarilo sa odoslať email.", "error");
    }
  };

  return (
    <Card sx={{ maxWidth: 800, margin: "20px auto", padding: "20px" }}>
      <CardContent>
        <Typography variant="h5" component="h2" gutterBottom sx={{ fontSize: "2em", marginBottom: "20px" }}>
          Kontaktujte nás
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="name"
                name="name"
                label="Mail"
                variant="outlined"
                fullWidth
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="email"
                name="email"
                label="Predmet"
                variant="outlined"
                fullWidth
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="message"
                name="message"
                label="Správa"
                variant="outlined"
                multiline
                rows={4}
                fullWidth
                required
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                type="submit"
                fullWidth
                disabled={!name || !email || !message} // disable the button if any field is empty
                sx={{
                  fontSize: "1.1em",
                  backgroundColor: "#3f51b5",
                  color: "white",
                  "&.Mui-disabled": {
                    backgroundColor: "#3f51b5",
                    color: "white",
                  },
                }}
              >
                Odoslať
              </Button>
            </Grid>
          </Grid>
        </form>
        <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
          <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: "100%" }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>

        <br />
        <Typography variant="h6" component="h3" gutterBottom sx={{ marginTop: "20px" }}>
          Kontaktné informácie
        </Typography>
        <br />
        <Typography variant="body1">
          <strong>Mladí programátori s.r.o</strong>
        </Typography>
        <Typography variant="body1">
          <strong>Adresa:</strong> Trieda Andreja Hlinku 606/39, 949 01, Nitra
        </Typography>
        <Typography variant="body1">
          <strong>IČO:</strong> 55655203
        </Typography>
        <Typography variant="body1">
          <strong>DIČ:</strong> 2322051602
        </Typography>
        <Typography variant="body1">
          <strong>Neplatca DPH</strong>
        </Typography>
        <br />
        <Typography variant="body1">
          <strong>Email: </strong>
          <a href="mailto:info@mladiprogramatori.sk" style={{ textDecoration: "none", color: "inherit" }}>
            info@mladiprogramatori.sk
          </a>
        </Typography>
        {/* <Typography variant="body1">
          <strong>Telefónne číslo: </strong>
          <a href="tel:0940014553" style={{ textDecoration: "none", color: "inherit" }}>
            0940 014 553
          </a>
        </Typography> */}
      </CardContent>
    </Card>
  );
};

export default Contact;
