import React, { useEffect } from "react";
import "./TermsAndConditions.css";

const TermsAndConditions: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="terms-and-conditions">
      <h1>Všeobecné obchodné podmienky</h1>
      <h2>Čl. I.</h2>
      <h2>VŠEOBECNÉ USTANOVENIA</h2>
      <p>
        <strong>Dátum aktualizácie:</strong> 08.09.2024
      </p>
      <p>
        1) Identifikačné údaje: Obchodné meno: Mladí programátori s. r. o. Sídlo: Trieda Andreja Hlinku 606/39 Nitra 949
        01 IČO: 55 655 203 Zápis: v Obchodnom registri Okresného súdu Nitra, oddiel: Sro, vložka č. 61121/N e-mail:
        info@mladiprogramatori.sk DIČ: 2322051602 (ďalej ako „obchodník“)
      </p>
      <p>
        2) Zákonný zástupca dieťaťa (žiaka), ktorý má záujem alebo si objedná poskytovanie kurzu je primerane označovaná
        ako „záujemca o kurz“, „objednávateľ“, „spotrebiteľ“.
      </p>
      <p>3) Obchodník a spotrebiteľ budú v týchto VOP spolu označovaní aj ako „zmluvné strany“.</p>
      <p>
        4) Obchodník, ktorý je prevádzkovateľom webovej stránky www.mladiprogramatori.sk, prostredníctvom ktorej
        dochádza k prihlasovaniu na kurzy informatiky určené pre žiakov základných škôl navštevujúcich od 2. do 9.
        ročníka, vydáva nasledujúce Všeobecné obchodné podmienky (ďalej ako „VOP“) Tieto VOP sa vzťahujú na právne
        vzťahy uzatvárané medzi obchodníkom na jednej strane a spotrebiteľom na druhej strane (ďalej ako „spotrebiteľské
        zmluvy“). VOP sú súčasťou každej spotrebiteľskej zmluvy a súhlas s nimi je podmienkou jej uzavretia.
      </p>
      <p>5) Tieto VOP nadobúdajú účinnosť dňa 08.09.2024</p>
      <p>
        6) Všetky zmluvné vzťahy sú v súlade s právnym poriadkom Slovenskej republiky v platnom znení a to najmä a nie
        výlučne zákonom č. 102/2014 Z. z. ochrane spotrebiteľa pri predaji tovaru alebo poskytovaní služieb na základe
        zmluvy uzavretej na diaľku alebo zmluvy uzavretej mimo prevádzkových priestorov predávajúceho v znení neskorších
        predpisov, zákonom č. 250/2007 Z. z. o ochrane spotrebiteľa v znení neskorších predpisov, Občiansky zákonník v
        znení neskorších predpisov a ďalšími predpismi.
      </p>
      <h2>Čl. II.</h2>
      <h2>ZÁKLADNÉ INFORMÁCIE O NÁS</h2>
      <p>
        1) Projekt Mladí programátori s. r. o. poskytujú mimoškolskú vzdelávaciu činnosť, ktorá sa zameriava na kurzy
        programovania. Tie prebiehajú:
      </p>
      <p>
        a) osobne priamo na vybraných základných školách vo viacerých mestách po skončení riadneho vyučovania. Kurzy sú
        určené pre tých žiakov, ktorí navštevujú danú základnú školu, na ktorej sa kurz uskutočňuje. Cena kurzu závisí
        od miesta výkonu kurzov vzhľadom na podmienky danej školy.
      </p>
      <p>
        b) prostredníctvom online kurzov, na ktoré sa žiak pripojí prostredníctvom dvoch aplikácií – MS Teams alebo
        Discord.
      </p>
      <p>
        2) Kurzy sú spoplatnené, konečná cena konkrétneho kurzu je uvedená priamo na webovej stránke obchodníka
        www.mladiprogramatori.sk.
      </p>

      <h2>Čl. III.</h2>
      <h2>INFORMÁCIE O POSKYTOVANEJ SLUŽBE</h2>
      <p>
        1) Obchodník poskytuje kurzy programovania na základných školách alebo online, ktoré sú určené pre žiakov
        základných škôl navštevujúcich od 2. do 9. ročníka, vo veku od 6 - 16 rokov. Kurzy sa poskytujú polročne a toto
        obdobie sa primerane prekrýva s obdobím polrokov školského roka v zmysle čl. IV. ods. 2 písm. g) (ďalej v zmysle
        čl. II. ods. 1 a 2 ako „kurz“ alebo „služba“).
      </p>
      <p>2) Kurzy nie sú poskytované jednotlivo.</p>
      <p>3) Základný priebeh prihlasovania na kurz:</p>
      <p>
        a) Za účelom prihlásenia žiaka na kurz je potrebné zaregistrovať sa na webovej stránke obchodníka
        www.mladiprogramatori.sk (ďalej ako „webová stránka obchodníka“). Po úspešnej registrácii bude záujemcovi o kurz
        vytvorený používateľský účet. Za účelom registrácie do účtu záujemca o kurz poskytne základné údaje, akými sú
        e-mail slúžiaci ako používateľské meno a vytvorí si používateľské heslo.
      </p>
      <p>
        b) Po prihlásení sa prostredníctvom svojich údajov do používateľského účtu, zákonný zástupca dieťaťa uvedie v
        sekcii „Môj profil“ svoje osobné údaje v požadovanom rozsahu, ktoré budú slúžiť ako fakturačné údaje.
      </p>
      <p>
        c) Vzhľadom na to, že výučba prebieha osobne v niekoľkých mestách a školách, záujemca o kurz si zvolí mesto a
        školu, ktorú navštevuje žiak, keďže kurzy sú určené len pre tých žiakov, ktorí navštevujú danú základnú školu,
        na ktorej sa kurz vykonáva. Záujemca o kurz preto môže prihlásiť žiaka len na taký kurz, ktorý sa vykonáva na
        základnej škole, ktorú žiak navštevuje. Z uvedeného dôvodu záujemca o kurz pri prihlasovaní žiaka vyplní
        špecifický kód, ktorý mu bude poskytnutý danou základnou školou, ktorú žiak navštevuje. Tento kód bude
        poskytnutý priamo školou prostredníctvom e-mailu s ďalšími doplňujúcimi informáciami o prebiehajúcom kurze.
      </p>
      <p>d) V prípade online kurzu záujemca o kurz môže prihlásiť žiaka bez ohľadu na základnú školu.</p>
      <p>
        e) V ďalšom kroku si záujemca o kurz vyberie druh programovacieho jazyka, v ktorom bude výučba vybraného kurzu
        prebiehať a to na škále od základných až po pokročilé programovacie jazyky. Je tak poskytnuté portfólio kurzov
        pre všetky vekové kategórie žiakov základnej školy.
      </p>

      <h2>Čl. IV.</h2>
      <h2>SPÔSOB UZATVÁRANIA ZMLUVY</h2>
      <p>
        1) Zmluva sa uzatvára medzi obchodníkom a zákonným zástupcom žiaka. Predmetom zmluvy je poskytovanie služby –
        kurzov programovania žiakov v zmysle čl. II. a III. týchto VOP a za podmienok uvedených v týchto VOP.
      </p>
      <p>2) Zmluva, na základe ktorej dôjde k plneniu zo strany obchodníka je uzatvorená nasledovným spôsobom:</p>
      <p>
        a) Záujemca o kurz si vytvorí jednoduchý užívateľský profil zadaním e-mailovej adresy a užívateľského hesla.
      </p>
      <p>
        b) Prihlásenie na kurz je zabezpečené a možné výhradne formou objednávkového formulára, nachádzajúceho sa na
        webovej stránke obchodníka.
      </p>
      <p>
        c) Záujemca o kurz si vyberie z ponuky dostupných kurzov v sekcii ponuka kurzov, s uvedeným predmetom služby –
        popis výučby kurzu, formou poskytovania kurzu (osobne/online), uvedenou cenou, dĺžkou trvania kurzu a miestom,
        kde sa bude vykonávať miesto základnej školy, kde sa vykonáva kurz osobnou formou. Záujemca o kurz v ďalšom
        kroku vyplní príslušné údaje podľa pokynov na webovej stránke obchodníka prostredníctvom objednávkového
        formulára, v ktorom vyplní požadované údaje a zašle objednávku. Táto objednávka zároveň predstavuje návrh na
        uzavretie zmluvy, predmetom ktorej je poskytovanie vybraného kurzu. Osobné údaje poskytované záujemcom o kurz
        budú spracúvané v súlade s čl. X. týchto VOP.
      </p>
      <p>
        d) Záujemcom o kurz vyplnený a obchodníkovi doručený objednávkový formulár je návrhom na uzavretie zmluvy, ktorý
        nie je pre obchodníka a ani pre záujemcu o kurz právne záväzný. Doručením vyplneného objednávkového formulára
        obchodníkovi sa začína proces uzavretia zmluvy uzavretej medzi obchodníkom a záujemcom o kurz.
      </p>
      <p>
        e) Obchodník po doručení návrhu zašle potvrdzujúci e-mail záujemcovi o kurz, ktorým obchodník potvrdí doručenie
        objednávky záujemcu. Doručením tohto e-mailu zároveň dôjde k rezervovaniu miesta v rámci zvoleného kurzu až do
        vybavenia objednávky obchodníkom.
      </p>
      <p>
        f) Vybavenie objednávky prebieha tak, že po tom, čo obchodník preverí dostupnosť vybraného kurzu, najneskôr v
        lehote 5 (slovom päť) pracovných dní zašle záujemcovi o kurz e-mail s potvrdením dostupnosti zvoleného kurzu a
        to buď prijatie objednávky v prípade dostupnosti daného kurzu alebo odmietnutím objednávky v prípade, že zvolený
        kurz už je kapacitne vyčerpaný alebo kurz nebol otvorený pre nedostatočný záujem.
      </p>
      <p>
        a) V prípade ak zvolený kurz nie je kapacitne vyčerpaný, momentom doručenia e-mailu o prijatí objednávky
        (záväzná akceptácia objednávky) záujemcovi o kurz dôjde k uzavretiu zmluvy. Od tohto momentu sú zmluvné strany
        takouto zmluvou viazané. Prílohou potvrdzujúceho e-mailu bude úplné znenie VOP v platnom znení ako aj formulár
        na odstúpenie od zmluvy v zmysle čl. IX. týchto VOP.
      </p>
      <p>
        b) V prípade ak zvolený kurz už bude kapacitne vyčerpaný, prípadne nebol z iného dôvodu otvorený, zašle
        obchodník záujemcovi o kurz e-mail, ktorým ho o tejto skutočnosti informuje a zároveň dôjde k stornovaniu
        vytvorenej objednávky. Súčasťou e-mailu o odmietnutí objednávky môže byť informácia o ďalších voľných kurzoch,
        ktoré si záujemca o kurz môže rovnakým spôsobom prostredníctvom formulára na webovej stránke obchodníka
        objednať.
      </p>
      <p>
        g) Zmluva sa uzatvára na dobu trvania kurzu, a to polročne. Toto obdobie sa primerane prekrýva s obdobím
        polrokov školského roka. Začiatok a koniec kurzu je uvedený v rámci ponuky dostupných kurzov na webovej stránke
        obchodníka. Kurzy nie sú poskytované jednotlivo.
      </p>
      <p>3) Podrobnosti o spôsobe platby sa nachádzajú v Čl. VII. Platobné podmienky.</p>

      <h2>Čl. V.</h2>
      <h2>PRÁVA A POVINNOSTI OBCHODNÍKA</h2>
      <p>1) Obchodník má právo na úplné zaplatenie ceny za poskytnutú službu od objednávateľa.</p>
      <p>
        2) Obchodník sa zaväzuje zabezpečiť na základe vykonanej a obchodníkom potvrdzenej objednávky [záväzná
        akceptácia objednávky podľa čl. IV. ods. 2 písm. f) bod f.a)] poskytovanú službu v dohodnutej cene a kvalite za
        podmienok v zmysle týchto VOP.
      </p>
      <p>
        3) Obchodník si vyhradzuje právo na zmenu osoby lektora, ak to povaha, charakter kurzu alebo iné okolnosti
        vyžadujú.
      </p>
      <p>
        4) Obchodník má právo v prípade rozhodnutia príslušného orgánu Slovenskej republiky o uzavretí učebných
        priestorov školy, na ktorej dochádza k poskytovaniu zvoleného kurzu, zmeniť osobnú výučbu na online formu a to
        do času, kým nie je možné výučbu zabezpečiť osobnou formou. Kurzy, u ktorých došlo k dočasnej zmene
        spôsobu/formy poskytovania, budú prebiehať primerane ako online kurzy v zmysle čl. II. ods. 1 písm. b).
      </p>
      <p>
        5) Obchodník má právo zmeniť výučbu z osobnej na online formu aj v prípade, ak nebude možné výučbu zabezpečiť
        štandardnou osobnou formou z iných dôvodov na strane obchodníka, o ktorých objednávateľa vopred primeranou,
        obvyklou formou informuje.
      </p>
      <p>6) Obchodník vyvinie úsilie o maximálnu funkčnosť a dostupnosť poskytovanej služby.</p>
      <p>
        7) Obchodník nezaručuje, že služba splní špecifické, obchodníkom negarantované požiadavky objednávateľa a
        nezodpovedá za škody tým spôsobené.
      </p>
      <p>
        8) Obchodník nezodpovedá za škodu spôsobenú stratou alebo poškodením uložených dát či za dočasné prerušenie
        služby.
      </p>
      <p>
        9) Obchodník prostredníctvom webovej stránky obchodníka a v jej lokálnych variáciách a subdoménach je povinný vo
        svojom profile vždy poskytnúť pravé a najaktuálnejšie informácie o svojej osobe. Tieto informácie sú pre zmluvné
        strany záväzné.
      </p>
      <p>
        10) Zmluvné strany berú na vedomie, že ak z týchto VOP vyplývajú ďalšie práva a povinnosti obchodníka, sú nimi
        viazané.
      </p>

      <p></p>

      <h2>Čl. VI.</h2>
      <h2>PRÁVA A POVINNOSTI OBJEDNÁVATEĽA</h2>
      <p>
        1) Objednávateľ má právo na poskytnutie služby v kvalite, termíne dohodnutom zmluvnými stranami a za podmienok v
        zmysle týchto VOP.
      </p>
      <p>
        2) Objednávateľ sa zaväzuje poskytnutú službu prevziať, zaplatiť obchodníkovi dohodnutú úhradu za službu v
        určenej lehote splatnosti spôsobom v závislosti od výberu spôsobu platby.
      </p>
      <p>3) Objednávateľ sa zaväzuje nepoškodzovať dobré meno prevádzkovateľa služby.</p>
      <p>
        4) Objednávateľ sa zaväzuje, že sa nebude k obchodníkovi, lektorom a iným obchodníkom povereným osobám správať v
        rozpore s dobrými mravmi.
      </p>
      <p>
        5) Objednávateľ sa zaväzuje, že nebude nijakým spôsobom kontaktovať konkrétneho lektora, ktorý realizoval výučbu
        zvoleného alebo iného kurzu, okrem nevyhnutných okolností.
      </p>
      <p>
        6) Objednávateľ sa ďalej zaväzuje neprejednávať s lektorom nijaké záležitosti týkajúce sa podmienok uzatvorenej
        zmluvy, prípadne podmienok a pravidiel poskytovania kurzov. V prípade potreby môže objednávateľ v tejto
        súvislosti kontaktovať obchodníka. Kontaktné údaje obchodníka sú uvedené v čl. I. ods. 1 týchto VOP.
      </p>
      <p>7) Objednávateľ nie je oprávnený úmyselne zneužívať systém obchodníka alebo inak preťažovať jeho servery.</p>
      <p>8) Objednávateľ nie je oprávnený poskytovať službu tretím osobám.</p>
      <p>9) Objednávateľ sa zaväzuje informovať obchodníka o všetkých zmenách v osobných údajoch.</p>
      <p>
        10) Objednávateľ prostredníctvom webovej stránky obchodníka a v jej lokálnych variáciách a subdoménach je
        povinný vo svojom profile vždy poskytnúť pravé a najaktuálnejšie informácie o svojej osobe. Tieto informácie sú
        pre zmluvné strany záväzné.
      </p>
      <p>
        11) Zmluvné strany berú na vedomie, že ak z týchto VOP vyplývajú ďalšie práva a povinnosti objednávateľa, obe sú
        nimi viazané.
      </p>

      <h2>Čl. VII.</h2>
      <h2>PLATOBNÉ PODMIENKY, CENA A PRIEBEH ÚHRADY KURZU</h2>
      <p>
        1) Celková cena za kurz predstavuje odplatu za poskytovanie služby – kurzov programovania v zmysle týchto VOP.
        Celková cena za kurz je zložená zo súčtu cien za jednotlivé kurzy podľa cien uvedených na webovej stránke
        obchodníka. Kurzy však nie sú poskytované jednotlivo.
      </p>
      <p>2) Dochádzka žiaka nemá vplyv na celkovú cenu za kurz, ak v týchto VOP nie je uvedené inak.</p>
      <p>
        3) Momentom doručenia e-mailu o prijatí objednávky [záväzná akceptácia objednávky podľa čl. IV. ods. 2 písm. f)
        bod f.a)] vzniká objednávateľovi nárok na zaplatenie celkovej ceny za kurz podľa podmienok v zmysle tohto článku
        VOP.
      </p>
      <p>4) Objednávateľ má možnosť výberu z dvoch spôsobov úhrady ceny za kurz a to nasledovne:</p>
      <p>
        a) Jednorazová, polročná platba – objednávateľ jednou platbou uhradí celkovú cenu za kurz za celé jedno polročné
        obdobie jednorazovo.
      </p>
      <p>
        b) Platba v mesačných splátkach – pravidelnou mesačnou úhradou v období jedného polroka poskytovania kurzu.
        Výška fakturovanej mesačnej sumy je určená z celkovej ceny kurzu jej rovnomerným rozdelením na mesačné splátky,
        s výnimkou prvej platby. Prvou platbou bude objednávateľ fakturovať navýšenú splatnú sumu za prvý aj nasledujúci
        mesiac. Objednávateľ sa zaväzuje túto navýšenú prvotnú splátku celkovej ceny kurzu uhradiť.
      </p>
      <p>
        5) V prípade výberu úhrady celkovej ceny kurzu v splátkach podľa čl. VII. ods. 4 písm. b), objednávateľ sa
        zaväzuje platbu uhrádzať vopred v mesiaci predchádzajúcom mesiacu, kedy začne fakturačná splatnosť splátky
        celkovej ceny za kurz na základe obchodníkom vystavenej faktúry, [ktorá prvej platby je v zmysle čl. VII. ods. 6
        uvedená].
      </p>
      <p>
        6) Splatnosť celkovej ceny na kurz bez ohľadu na spôsob úhrady bude uvedená na príslušnej faktúre ak v týchto
        VOP nie je uvedené inak.
      </p>
      <h3>7) Priebeh úhrady:</h3>
      <p>
        a) V nadväznosti na uvedené, objednávateľ má možnosť vykonať úhradu za kurz prostredníctvom platobnej brány
        nachádzajúcej sa priamo na webovej stránke obchodníka po tom ako vytvorí objednávku.
      </p>
      <p>
        b) V prípade platby bezhotovostným spôsobom prostredníctvom platobnej brány zabezpečovanej službou Stripe,
        objednávateľ po uskutočnení objednávky a po prihlásení na webovej stránke obchodníka v sekcii môj profil vyberie
        túto možnosť - platba kartou (Stripe). Táto možnosť objednávateľa automaticky presmeruje na webové stránky
        poskytovateľa služby Stripe, kde sú uvedené údaje ako meno žiaka, zvolená forma kurzu, v prípade osobného kurzu
        aj zvolená škola, dĺžka kurzu a cena. Následne objednávateľ zadá údaje podľa predvolených požiadaviek a
        uskutoční platbu. Bez ohľadu na úspešnosť platby je objednávateľ automaticky presmerovaný späť na webovú stránku
        obchodníka, kde bude oboznámený s výsledkom platby. Následne bude objednávateľovi zaslaný e-mail s potvrdením o
        zaplatení.
      </p>
      <p>
        c) Podmienkou pre využitie tohto spôsobu je, aby objednávateľ disponoval platobnou kartou, ktorá umožňuje prevod
        peňažných prostriedkov v rámci tohto spôsobu platby.
      </p>
      <p>
        d) Druhým spôsobom úhrady ceny je aj platba prostredníctvom bankového prevodu na základe vystavenej faktúry
        obchodníkom objednávateľovi po potvrdení objednávky obchodníkom [záväzná akceptácia objednávky podľa čl. IV.
        ods. 2 písm. f) bod f.a)] vykonanej objednávateľom. Obchodník zašle záujemcovi o kurz uvedenú na faktúre počas
        predchádzajúcej lehoty v lehote splatnosti uvedenej v zmysle čl. VII. ods. 6 týchto VOP (ďalej „lehota
        splatnosti“ je určená na 7 dní. Lehota splatnosti je kratšia ako 7 (slovom sedem) dní. Následne bude
        objednávateľ oboznámený e-mailom o správnosti a zaplatení.
      </p>
      <p>
        e) Minimálna výška finančných prostriedkov na účet obchodníka sa skladá z dvojnásobku ceny mesačnej úhrady a
        variabilného symbolu sa skladá z dvojčíslia mesiaca v ktorom sa platba zúčtuje a unikátneho šesťčíselného čísla
        platby.
      </p>
      <p>
        f) Po vykonaní úhrady za kurz a jej pripísaní na účet obchodníka, obchodník vydá záujemcovi o kurz príslušný
        daňový doklad.
      </p>
      <p>g) Platbu za kurz je možné vykonať výlučne bezhotovostne v zmysle tohto čl. VII. týchto VOP.</p>
      <p>h) Platba ceny za kurz sa uskutočňuje v mene EUR.</p>
      <p>
        8) V prípade výberu jednorazovej polročnej platby podľa čl. VII. ods. 4 písm. a), objednávateľ má právo na zľavu
        vo výške 50 eurocentov z každého jednotlivého kurzu. V zmysle výpočtu celkovej ceny za kurz v zmysle čl. VII.
        ods. 1, zľavnená celková cena za kurz sa preto bude skladať zo súčtu cien za jednotlivé kurzy, ponížených o 50
        eurocentov.
      </p>
      <p>
        9) Záväzné a aktuálne ceny za kurzy (cenníky) garantované obchodníkom sú uvedené na webovej stránke obchodníka.
        Pre objednávateľa je záväzná cena uvedená na web stránke obchodníka po vyplnení objednávkového formulára počas
        prihlasovania na kurz.
      </p>
      <p>
        10) Obchodník si vyhradzuje právo jednostranne zmeniť ceny za kurzy uvedené na webovej stránke obchodníka. Nové
        ceny kurzov nadobúdajú platnosť dňom ich zverejnenia na webovej stránke obchodníka, pričom sa zmenené ceny
        netýkajú kurzov už potvrdených v zmysle čl. IV. ods. 2 písm. f) bod f.a) týchto VOP.
      </p>
      <p>11) Cena kurzu je konečná, uvedená s DPH.</p>
      <p>12) Obchodník nie je platiteľom DPH.</p>

      <h2>Čl. VIII.</h2>
      <h2>REKLAMAČNÝ PORIADOK</h2>
      <p>
        1) Obchodník zodpovedá za chyby a nedostatky poskytnutej služby – kurzu. Objednávateľ je povinný reklamáciu u
        obchodníka bezodkladne uplatniť v zmysle tohto reklamačného poriadku.
      </p>
      <p>
        2) Tento reklamačný poriadok sa vzťahuje na reklamácie služieb poskytovaných obchodníkom, zakúpených
        objednávateľom prostredníctvom webovej stránky obchodníka a vzťahuje sa na všetky obchodné prípady, báze by boli
        inak individuálne dojednané.
      </p>
      <p>
        3) Právo objednávateľa uplatniť si reklamáciu služby prichádza do úvahy len pri službe, ktorá vykazuje chyby,
        ktoré zavinil obchodník a táto služba bola u obchodníka riadne zakúpená.
      </p>
      <p>
        4) Objednávateľ môže skontrolovať prevádzku služby po jej poskytnutí obchodníkom. Ak tak neurobí, môže uplatniť
        nároky z chýb zistených pri tejto kontrole len keď preukáže, že tieto chyby mala služba v čase jej poskytnutia.
      </p>
      <p>
        5) Objednávateľ má právo na odstránenie chýb poskytnutej služby bezplatne po tom, ako to obchodníkovi oznámi a
        preukáže zakúpenie služby u prevádzkovateľa.
      </p>
      <p>
        6) Objednávateľ sa zaväzuje, že chyby či nedostatky poskytnutej služby bude u obchodníka reklamovať bez
        zbytočného odkladu. V opačnom prípade právo uplatniť si odškodnenie chýb v súvislosti s využívaním služby
        zaniká.
      </p>
      <p>
        7) Reklamácie sú vybavované obchodníkom počas pracovných dní. Reklamácie možno uplatniť prostredníctvom
        e-mailovej adresy obchodníka uvedenej v čl. I. ods. 1 týchto VOP, prípadne telefonicky alebo písomne. V prípade
        nejasností má objednávateľ právo za týmto účelom obchodníka kontaktovať.
      </p>
      <p>
        8) Uplatnenie reklamácie prebieha tak, že bez ohľadu na spôsob, akým objednávateľ reklamáciu uplatní, uvedie
        základné údaje obchodníka v rozsahu čl. I. ods. 1 týchto VOP. Ďalej objednávateľ uvedie svoje meno, priezvisko,
        titul, adresu bydliska, telefónny kontakt alebo e-mailovú adresu a dátum. So žiadou reklamovaného plnenia
        objednávateľ uvedie, kedy s predávajúcim uzavrel zmluvu, popíše chyby a nedostatky poskytnutého kurzu, uvedie
        číslo objednávky a faktúry, dátum objednania služby, dátum dodania služby (aký bol kurz vykonaný), názov kurzu
        tak, ako je uvedený vo faktúre, požiadavku ako chce aby bola reklamácia obchodníkom vybavená. V prípade
        požiadavky vrátenia peňazí, za predpokladu úspešnej reklamácie, uvedie IBAN účtu, na ktoré majú byť prostriedky
        vrátené. Ak sa k reklamácii prikladajú ďalšie listiny, objednávateľ uvedie ich zoznam a označenie. V prípade
        zasielania reklamácie prostredníctvom pošty uvedie informáciu aj týmto spôsobom, objednávateľ takýto dokument
        vlastnoručne podpíše.
      </p>
      <p>9) Kontaktné údaje obchodníka sú uvedené v čl. I. ods. 1 týchto VOP.</p>
      <p>10) Obchodník sa zaväzuje prijať reklamáciu na posúdenie.</p>
      <p>
        11) Osoba poverená na prijímanie reklamácií je Bc. Daniel Čok, konateľ obchodníka, a to prostredníctvom
        kontaktných údajov podľa čl. I. ods. 1 týchto VOP.
      </p>
      <p>
        12) Po tom ako objednávateľ uplatní reklamáciu, vydá mu prostredníctvom e-mailu obchodník potvrdenie o uplatnení
        reklamácie formou e-mailu bez zbytočného odkladu [v čase podľa čl. I. ods. 1]. Súčasťou e-mailu budú vymenované
        nedostatky a chyby služby uvedené objednávateľom v reklamácii a poučenie objednávateľa o jeho právach.
      </p>
      <p>
        13) Objednávateľ sa môže rozhodnúť, ktoré z práv príslušajúcich mu v zmysle § 621 Občianskeho zákonníka si
        primerane uplatňuje a teda právo na odstránenie vady služby, právo na primeranú zľavu z ceny alebo právo
        odstúpiť od zmluvy.
      </p>
      <p>
        14) Obchodník určí spôsob vybavenia reklamácie bez zbytočného odkladu, najneskôr do 30 (slovom tridsiatich) dní
        odo dňa uplatnenia reklamácie.
      </p>
      <p>
        15) Po uplynutí lehoty na vybavenie reklamácie má spotrebiteľ právo od zmluvy odstúpiť alebo má právo na nové
        poskytnutie celej služby alebo jej časti (ak napr. bola reklamovaná jednotlivá vyučovacia hodina kurzu).
      </p>
      <p>
        16) O ukončení reklamačného konania a výsledku reklamácie informuje obchodník objednávateľa prostredníctvom
        e-mailu a súčasne bude objednávateľovi doručený reklamačný protokol.
      </p>
      <p>
        17) Obchodník môže namiesto odstránenia chýb a nedostatkov služby poskytnúť objednávateľovi novú službu za
        predpokladu, že to objednávateľovi nespôsobí závažné ťažkosti.
      </p>
      <p>18) Vybavenie reklamácie a ukončenie reklamačného konania obchodníkom prebieha nasledovným spôsobom:</p>
      <ul>
        <li>a) odstránením chyby služby,</li>
        <li>b) poskytnutím novej služby,</li>
        <li>c) vyplatením primeranej zľavy z úhrady za službu,</li>
        <li>d) vrátením úhrady za službu (alebo jej časti v závislosti od rozsahu reklamácie),</li>
        <li>e) odôvodneným zamietnutím reklamácie služby.</li>
      </ul>
      <p>
        19) O spôsobe vybavenia reklamácie a o vybavení reklamácie obchodník objednávateľa informuje tak, že mu vydá
        najneskôr do 30 dní odo dňa uplatnenia reklamácie potvrdenie/doklad prostredníctvom e-mailu.
      </p>
      <p>
        20) V prípade poskytnutia novej služby dostane objednávateľ doklad, na ktorom bude uvedená nová služba. Ďalšie
        prípadné reklamácie sa uplatňujú na základe pôvodnej faktúry a dokladu o úhrade za službu a toto reklamačné
        obdobie začína.
      </p>
      <p>21) Vybavenie reklamácie sa vzťahuje len na vady uvedené vo formulári na uplatnenie reklamácie.</p>
      <p>
        22) V prípade, že obchodník ukončí reklamačné konanie ako odôvodnené zamietnutie reklamácie, ale vada služby
        objektívne existuje a nebola odstránená, môže si objednávateľ uplatniť svoje právo na odstránenie vady služby
        prostredníctvom príslušných orgánov.
      </p>

      <h2>Čl. IX.</h2>
      <h2>ODSTÚPENIE OD ZMLUVY</h2>
      <p>
        1) Obchodník a objednávateľ má právo odstúpiť od zmluvy v súlade s podmienkami uvedenými v týchto VOP a
        príslušných právnych predpisoch.
      </p>
      <p>
        2) Obchodník a objednávateľ je oprávnený odstúpiť od zmluvy bez uvedenia dôvodu v lehote podľa čl. IX. ods. 8
        alebo z akéhokoľvek dôvodu, kým nedošlo k začatiu poskytovania služby.
      </p>
      <p>3) Obchodník môže odstúpiť od zmluvy ak:</p>
      <ul>
        <li>
          a) objednávateľ neprihlásil žiaka v súlade s týmito VOP, viedol nepravdivé alebo neúplné údaje v zmluve,
          formulári, e-maile týkajúcom sa konkrétnej služby, ktorý odoslal obchodníkovi alebo sa ukázalo ako nepravdivé
          alebo neúplné tie údaje, ktoré poskytol objednávateľ obchodníkovi;
        </li>
        <li>
          b) objednávateľ prihlásil žiaka na kurz vykonávaný osobnou formou, ktorý sa koná na základnej škole, ktorú
          žiak nenavštevuje;
        </li>
        <li>
          c) objednávateľ neposkytol bezodkladne obchodníkovi dostatočné súčinnosti pri poskytovaní služby, osobitne ak
          bolo doručené akýmkoľvek spôsobom pozmenené dokumenty poskytujúce obchodníkovi a súvisiace s poskytnutím
          služby;
        </li>
        <li>d) objednávateľ počas poskytovania služby nedoručil potrebné informácie uvedené v týchto VOP;</li>
        <li>
          e) dôjde k naplneniu kapacit kurzu, prípad v období pred jeho plnením služby, objednávateľovi sa zaviazal k
          dátumu zúčtovania platby obchodníkom;
        </li>
        <li>
          f) z dôvodu nedostupnosti alebo nefunkčnosti služby nie je schopný zabezpečiť službu objednávateľovi a
          nedohodne sa na náhradnom plnení, ktoré je uvedené na webovej stránke obchodníka alebo dohodnuté s
          objednávateľom;
        </li>
        <li>
          g) žiak závažným spôsobom narušuje priebeh kurzu alebo ohrozuje zdravie ďalších účastníkov kurzu, lektorov a
          podobne;
        </li>
        <li>
          h) sa objednávateľ alebo žiak opakovane správa k obchodníkovi, jeho zamestnancom alebo ním povereným osobám v
          rozpore s dobrými mravmi;
        </li>
        <li>
          i) po začatí kurzu dôjde k zníženiu počtu prihlásených žiakov na 5. Obchodník sa v takom prípade zaväzuje
          poskytnúť obdobné plnenie v súlade s týmito VOP alebo vrátenie zaplatenej ceny kurzu zníženú o už poskytnuté
          plnenie zo strany obchodníka v súlade s čl. IX. ods. 16;
        </li>
        <li>
          j) je objednávateľ v omeškaní s úhradou svojich záväzkov vyplývajúcich z tejto zmluvy o viac ako jeden mesiac.
        </li>
      </ul>
      <p>4) Obchodník má právo požadovať ceny za služby, ktoré spotrebiteľovi boli dodané.</p>
      <p>
        5) Obchodník sa zaväzuje vrátiť spotrebiteľovi platby rovnakým spôsobom, aký použil spotrebiteľ pri svojej
        platbe. Spotrebiteľ má právo dohodnúť sa s predajcom na inom spôsobe platby, ak v súvislosti s tým
        spotrebiteľovi nebudú účtované žiadne ďalšie poplatky.
      </p>
      <p>
        6) Obchodník má právo požadovať platbu za služby, ktoré spotrebiteľovi boli dodané a to aj v prípade, ak nebol
        objednávateľovi prevzaté (žiak sa kurzu nezúčastní).
      </p>
      <p>
        7) V prípade, že sa pri niektorej službe vyskytnú ceny, ktorá je evidentne chybná (napr. Cena 0,- eur, 1,- eur
        alebo iná zjavne ekonomicky neprimerane nízka cena a pod.), obchodník nemá povinnosť poskytnúť službu za chybnú
        cenu. V takomto prípade obchodník môže objednávateľovi ponúknuť poskytnutie služby za riadnu cenu. Ak
        objednávateľ s riadnou cenou nesúhlasí, môže obchodník odstúpiť od zmluvy.
      </p>
      <p>
        8) Objednávateľ môže od zmluvy uzavretej s obchodníkom v zmysle týchto VOP odstúpiť bez udania dôvodu v lehote
        14 dní odo dňa uzavretia zmluvy záväzná akceptácia objednávky podľa čl. IV. ods. 2 písm. f) bod f.a). Lehota na
        odstúpenie od zmluvy uplynie po 14 dňoch odo dňa uzavretia zmluvy alebo odo dňa obchodníkom potvrdenej
        objednávky [záväzná akceptácia objednávky podľa čl. IV. ods. 2 písm. f) bod f.a)].
      </p>
      <p>
        9) Vzhľadom na špecifiká a povahu poskytovaných služieb, ak objednávateľ súhlasil so začiatím poskytovania
        služby počas lehoty na odstúpenie od zmluvy, má povinnosť zaplatiť cenu za časť služby, ktorá už bola poskytnutá
        – cenu za skutočne poskytnuté plnenie. A to v rozsahu do dňa doručenia odstúpenia od zmluvy obchodníkovi.
      </p>
      <p>
        10) Spotrebiteľ môže uplatniť svoje právo odstúpiť od zmluvy písomne a to aj prostredníctvom e-mailovej adresy
        obchodníka uvedenej v čl. I. ods. 1 týchto VOP alebo formulára na odstúpenie od zmluvy, ktorý sa nachádza na
        webovej stránke obchodníka, kde po prihlásení sa do používateľského účtu v sekcii „Na stiahnutie“ má možnosť
        stiahnuť si formulár na odstúpenie od zmluvy. Spotrebiteľ zadá údaje obchodníkovi v zmysle čl. I. ods. 1 týchto
        VOP a svoje osobné údaje (meno a priezvisko, adresy, telefónne číslo alebo e-mailovú adresu a IBAN číslo účtu).
        Spotrebiteľ vyhlási, že odstupuje od zmluvy na vybraný konkrétny kurz, ktorý si zvolil, uvedie dátum objednania
        alebo prijatia služby. Spotrebiteľ uvedie dátum a svoj vlastnoručný podpis, ktorý sa nachádza v prílohe
        obchodníka. Ak je odstúpenie vykonané elektronicky prostredníctvom e-mailu, obchodník zašle zákazníkovi
        potvrdenie odstúpenia od zmluvy prostredníctvom pošty alebo iným podobným spôsobom, dokument odstúpenia sa
        priloží do adresy sídla obchodníka.
      </p>
      <p>
        11) Formulár na odstúpenie od zmluvy je súčasťou potvrdzujúceho e-mailu a nachádza sa na stiahnutie aj na
        webovej stránke obchodníka po prihlásení do používateľského prostredia:{" "}
        <a href="https://www.mladiprogramatori.sk/#for-download">https://www.mladiprogramatori.sk/#for-download</a>
      </p>
      <p>
        12) Lehota na odstúpenie od zmluvy je zachovaná ak oznámenie o odstúpení od zmluvy bolo odoslané obchodníkovi
        najneskôr v deň lehoty.
      </p>
      <p>
        13) Po tom, ako dôjde k doručeniu oznámenia o odstúpení od zmluvy zo strany spotrebiteľa v súlade s týmito VOP,
        obchodník sa zaväzuje vrátiť všetky platby a úhrady poskytnuté spotrebiteľom s výnimkou úhrady za časť
        služby/služieb, ktoré už bola spotrebiteľovi poskytnutá. Platby a úhrady budú vrátené najneskôr do 14 dní odo
        dňa doručenia odstúpenia od zmluvy obchodníkovi.
      </p>
      <p>
        14) Vrátenie platby bude uskutočnené rovnakým spôsobom, akým došlo k úhrade ceny za službu, ibaže sa obchodník s
        objednávateľom dohodli inak.
      </p>
      <p>15) Dôkazné bremeno o uplatnení práva na odstúpenie od zmluvy znáša spotrebiteľ.</p>
      <p>
        16) V prípade odstúpenia od zmluvy a celkový cena za kurz objednávateľ uhradí jednorazovo podľa čl. VII. ods. 4
        písm. a), bola mu poskytnutá zľava podľa čl. VII. ods. 8, objednávateľovi bude vrátená buď celková cena za kurz
        po zľave (ak ešte nedošlo k poskytnutiu kurzu) alebo alikvotná časť celkovej ceny za kurz po zľave za časť
        plnenia obchodníkom, ktoré nebolo objednávateľovi poskytnuté (v závislosti od momentu odstúpenia od zmluvy a
        času poskytovania služby).
      </p>
      <p>
        17) Zmluvné strany berú na vedomie, že ak z týchto VOP vyplývajú ďalšie ustanovenia o práve obchodníka alebo
        objednávateľa na odstúpenie od zmluvy, obe sú nimi viazané.
      </p>

      <h2>Čl. X.</h2>
      <h2>OSOBNÉ ÚDAJE A ICH OCHRANA</h2>
      <p>
        1) Obchodník prijal primerané opatrenia k spracúvaniu osobných údajov, v zmysle zákona č. 18/2018 Z. z. o
        ochrane osobných údajov a podľa nariadenia GDPR. Objednávateľ potvrdzuje, že bol oboznámený so spracúvaním jeho
        osobných údajov v zmysle pravidiel nachádzajúcich sa na webovej stránke obchodníka, konkrétne:{" "}
        <a href="https://www.mladiprogramatori.sk/#/privacy-policy">
          https://www.mladiprogramatori.sk/#/privacy-policy
        </a>
      </p>

      <h2>Čl. XI.</h2>
      <h2>ALTERNATÍVNE RIEŠENIE SPOROV</h2>
      <p>
        1) Dozor nad dodržiavaním povinností obchodníka voči spotrebiteľovi je Slovenská obchodná inšpekcia, konkrétne
        pracovisko Inšpektorát SOI pre Bratislavský kraj, Prievozská 32, P. O. Box 5, 820 07 Bratislava 27, odbor
        technickej kontroly výrobkov a ochrany spotrebiteľa a právny odbor, tel. č.: 02/58272 172, 02/58272 104 fax č.:
        02/ 58272 170, email: <a href="mailto:ba@soi.sk">ba@soi.sk</a>.
      </p>
      <p>
        2) Spotrebiteľ je oprávnený obrátiť sa na obchodníka so žiadosťou o nápravu v prípade ak medzi spotrebiteľom a
        obchodníkom vznikne spor z uplatnenia práv zo zodpovednosti za vady alebo ak sa spotrebiteľ domnieva, že
        obchodník porušil iné práva spotrebiteľa. Ak obchodník na takúto žiadosť odpovie zamietavo, alebo na ňu
        neodpovie do 30 dní odo dňa jej odoslania, má spotrebiteľ právo podať návrh na začatie alternatívneho riešenia
        sporu subjektu alternatívneho riešenia sporov. Subjektmi alternatívneho riešenia sporov sú orgány a oprávnené
        právnické osoby v zmysle § 3 zákona č. 391/2015 Z. z. o alternatívnom riešení spotrebiteľských sporov, a to
        napr. Slovenská obchodná inšpekcia, pričom používateľ spotrebiteľ je oprávnený si vybrať subjekt alternatívneho
        riešenia spotrebiteľských sporov, na ktorý sa obráti.
      </p>
      <p>
        3) E-mailový kontakt na obchodníka je uvedený v čl. I. ods. 1 týchto VOP. Spotrebiteľ pri podaní návrhu
        postupuje v zmysle § 12 zákona č. 391/2015 Z. z. o alternatívnom riešení spotrebiteľských sporov. Alternatívnym
        riešením sporov možno urovnať spor medzi spotrebiteľom a obchodníkom vyplývajúci zo spotrebiteľskej zmluvy,
        resp. súvisiaci so spotrebiteľskou zmluvou. Hodnota sporu, ktorý sa urovnáva alternatívnym riešením sporov, musí
        presahovať sumu 20 eur. Subjekt alternatívneho riešenia sporov môže požadovať od spotrebiteľa poplatok za
        začatie alternatívneho riešenia sporov najviac sumu päť eur vrátane dane z pridanej hodnoty, a to najskôr
        súčasne so zaslaním oznámenia o začatí alternatívneho riešenia sporu.
      </p>

      <h2>Čl. XII.</h2>
      <h2>ZÁVEREČNÉ USTANOVENIA</h2>
      <p>
        1) Obchodník si vyhradzuje právo na zmenu týchto VOP. Povinnosť písomného oznámenia zmeny v týchto všeobecných
        obchodných podmienkach je splnená umiestnením na webovej stránke obchodníka.
      </p>
      <p>2) V prípade, ak je zmluva uzatvorená v písomnej forme, akákoľvek jej zmena musí mať písomnú formu.</p>
      <p>3) Zmluvné strany sa dohodli, že komunikácia medzi nimi bude uskutočňovaná primárne vo forme e-mailu.</p>
      <p>
        4) Na vzťahy neupravené týmito všeobecnými obchodnými podmienkami sa vzťahujú príslušné ustanovenia zákona č.
        108/2024 o ochrane spotrebiteľa, č. 40/1964 Občiansky zákonník a ďalšie súvisiace predpisy.
      </p>

      <p>V Nitre, dňa 08.09.2024</p>
    </div>
  );
};

export default TermsAndConditions;
