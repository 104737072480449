import React, { useState } from "react";
import { Container, Paper, Tab, Tabs, Box, Typography } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import SendIcon from "@mui/icons-material/Send";
import ChallengeInfo from "./ChallengeInfo";
import Task from "./Task";
import SubmissionForm from "./SubmissionForm";
import { keyframes } from "@emotion/react";

const fadeInAnimation = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`challenge-tabpanel-${index}`}
      aria-labelledby={`challenge-tab-${index}`}
      {...other}
      style={{ animation: `${fadeInAnimation} 0.5s` }}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const MonthlyChallenges = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Container>
      <Paper elevation={3} sx={{ my: 2, overflow: "hidden" }}>
        <Tabs value={value} onChange={handleChange} aria-label="challenge tabs" variant="fullWidth">
          <Tab icon={<AddCircleOutlineIcon />} label="Informácie o výzve" {...a11yProps(0)} />
          <Tab icon={<HelpOutlineIcon />} label="Úlohy a nápovedy" {...a11yProps(1)} />
          <Tab icon={<SendIcon />} label="Odošlite svoje riešenie" {...a11yProps(2)} />
        </Tabs>
      </Paper>
      <TabPanel value={value} index={0}>
        <ChallengeInfo />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Task />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <SubmissionForm />
      </TabPanel>
    </Container>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `challenge-tab-${index}`,
    "aria-controls": `challenge-tabpanel-${index}`,
  };
};

export default MonthlyChallenges;
