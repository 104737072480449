import { FaArrowUp, FaRegCopyright } from "react-icons/fa";
import { AppBar, Toolbar, Typography, Link, Grid } from "@mui/material";

interface FooterProps {
  copyrightText: string;
  isDarkTheme: boolean;
}

const Footer = ({ copyrightText, isDarkTheme }: FooterProps) => {
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: isDarkTheme ? "#222" : "#A53F29", zIndex: 2000 }}>
      <Toolbar>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12} sm={4} style={{ textAlign: 'center' }}>
            <Typography variant="body2" color="inherit" sx={{ ml: 1 }}>
              <FaRegCopyright />
              {copyrightText}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={2} style={{ textAlign: 'center' }}>
            <Link href="#/cookiePolicy" color="inherit" sx={{ mx: 1, textDecoration: "none" }}>
              Zásady používania súborov cookie
            </Link>
          </Grid>
          <Grid item xs={12} sm={2} style={{ textAlign: 'center' }}>
            <Link href="#/terms-and-conditions" color="inherit" sx={{ mx: 1, textDecoration: "none" }}>
              Všeobecné obchodné podmienky
            </Link>
          </Grid>
          <Grid item xs={12} sm={4} style={{ textAlign: 'center' }}>
            <Link onClick={handleScrollToTop} color="inherit" style={{ cursor: "pointer" }}>
              <FaArrowUp size={20} />
            </Link>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Footer;
