import React, { useState } from 'react';
import { Button, TextField, Paper, Typography, Snackbar, IconButton, Slider } from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CloseIcon from '@mui/icons-material/Close';
import { keyframes } from '@emotion/react';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const SubmissionForm: React.FC = () => {
  const [name, setName] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const [difficulty, setDifficulty] = useState<number>(5);
  const [submitted, setSubmitted] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (file) {
      // Logika pre spracovanie odosielania súboru na server by bola tu
      setSubmitted(true);
      setSnackbarOpen(true);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Paper elevation={2} sx={{ padding: '20px', backgroundColor: '#f1f1f1', animation: `${fadeIn} 500ms` }}>
      <Typography variant="h6" sx={{ marginBottom: '20px' }}>Odošlite svoje riešenie</Typography>
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Vaše meno"
          variant="outlined"
          value={name}
          onChange={e => setName(e.target.value)}
          sx={{ marginBottom: '12px' }}
        />
        <input
          accept="*/*"
          style={{ display: 'none' }}
          id="raised-button-file"
          multiple
          type="file"
          onChange={handleFileChange}
        />
        <label htmlFor="raised-button-file">
          <Button variant="contained" component="span" startIcon={<FileUploadIcon />} sx={{ marginBottom: '12px' }}>
            Nahrať súbor
          </Button>
        </label>
        {file && <Typography variant="body2" sx={{ marginBottom: '12px' }}>{file.name}</Typography>}
        <Typography variant="body2" sx={{ marginBottom: '12px' }}>Zvoľte obtiažnosť:</Typography>
        <Slider
          value={difficulty}
          onChange={(e, newValue) => setDifficulty(newValue as number)}
          step={1}
          marks
          min={1}
          max={10}
          valueLabelDisplay="auto"
          sx={{ marginBottom: '20px' }}
        />
        <Button variant="contained" color="primary" type="submit" disabled={!file || !name}>Odoslať</Button>
      </form>
      {submitted && file && (
        <Typography variant="body1" sx={{ marginTop: '20px' }}>
          Odoslané: {file.name} dňa {new Date().toLocaleDateString()} o {new Date().toLocaleTimeString()}
        </Typography>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message="Riešenie úspešne odoslané!"
        action={
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackbar}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </Paper>
  );
};

export default SubmissionForm;
