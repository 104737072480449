// src/data/coursesData.ts
export const courses = [
    {
        title: "Úvod do programovania v jazyku Scratch",
        language: "Scratch",
        description:
            "Programovanie v jazyku Scratch je určené pre najmenších. Spolu sa naučíme, čo vlastne programovanie je a ako fungujú základné programovacie princípy. Deti si osvoja schopnosti, ktoré im pomôžu nielen v informatike, ale aj v bežnom živote, ako sú analytické myslenie a tímová práca.",
        difficulty: "Jednoduchá",
        duration: "1 hodina (1x týždenne, celý polrok)",
        capacity: 5,
        suitableFor: "1. stupeň - (Deti od 2. - 5. ročník)",
        prerequisites: "Žiadne",
        price: "8€ / hod",
        reviews: [
            { user: "Eva L.", comment: "Skvelý úvod do programovania, moje dieťa to milovalo." },
            { user: "Tomáš D.", comment: "Výborné pre začínajúcich programátorov." },
        ],
        howItWorks: "Tento kurz sa tiež koná online cez Zoom. Každý týždeň študenti dostanú nové úlohy a projekty, ktoré budú pracovať a prezentovať na hodine. Kurz je zameraný na deti, ktoré nikdy predtým neprogramovali, a je plný interaktívnych hier a aktivít, ktoré deti učia základy programovania hravým spôsobom.",
    },
    {
        title: "Pokročilé programovanie v jazyku Scratch",
        language: "Scratch",
        description:
            "Pokročilé programovanie v jazyku Scratch je určené pre deti, ktoré už majú s programovaním skúsenosti a chcú sa naučiť niečo nové. V tomto kurze sa naučíte, ako vytvoriť vlastný program, ktorý bude obsahovať všetky základné prvky programovania. Deti sa naučia nielen kódovať, ale aj logicky myslieť a riešiť problémy.",
        difficulty: "Stredná",
        duration: "1 hodina (1x týždenne, celý polrok)",
        capacity: 5,
        suitableFor: "1. stupeň - (Deti od 2. - 9. ročník)",
        prerequisites: "Scratch I - základy programovania absolvované",
        price: "8€ / hod",
        reviews: [
            { user: "Jana K.", comment: "Veľmi dobrý kurz, moje dieťa si ho veľmi užilo!" },
            { user: "Martin S.", comment: "Výborný učiteľ a skvelé materiály." },
        ],
        howItWorks: "Kurz prebieha online cez platformu Zoom. Deti sa stretnú s lektorom raz týždenne na 1 hodinu. Počas kurzu budú deti pracovať na vlastných projektoch, ktoré budú postupne dokončovať a zdieľať so svojimi spolužiakmi. Ku každému projektu sú dostupné aj domácich úlohy, ktoré deti motivujú ďalej skúmať a učiť sa.",
    },
    {
        title: "Úvod do programovania v jazyku Roblox",
        language: "Lua",
        description:
            "Priblížte sa k svetu digitálnej tvorby a herného dizajnu prostredníctvom platformy Roblox. Roblox nie je len obľúbenou hernou platformou, ale aj nástrojom, ktorý umožňuje užívateľom vytvárať vlastné hry a virtuálne prostredia. Tento kurz zahŕňa základy programovania v jazyku Lua a základy herného dizajnu, čo deťom umožňuje rozvíjať ich kreatívne myslenie.",
        difficulty: "Stredná",
        duration: "1 hodina (1x týždenne, celý polrok)",
        capacity: 5,
        suitableFor: "2. stupeň - (Deti od 2. - 5. ročník)",
        prerequisites:
            "Scratch I - základy programovania absolvované alebo Python I - základy programovania absolvované",
        price: "8€ / hod",
        reviews: [
            { user: "Petra M.", comment: "Deti milujú Roblox, a tento kurz im veľmi pomohol pochopiť, ako hry fungujú." },
            { user: "Lukáš B.", comment: "Skvelý kurz pre začínajúcich programátorov." },
        ],
        howItWorks: "Kurz sa koná online cez Zoom, kde sa študenti stretávajú s lektorom raz týždenne na 1 hodinu. Počas hodiny študenti pracujú na reálnych herných projektoch v prostredí Roblox Studio. Každý týždeň dostanú študenti nové úlohy na zlepšenie svojich zručností v jazyku Lua a hernom dizajne.",
    },
];
